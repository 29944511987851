
<template>
  <div class="BaseTable_I">
    <div class="tab_Top">
      <el-menu
        :default-active="activeIndex"
        class="el-menu-demo"
        mode="horizontal"
        @select="handleSelect">
        <el-menu-item index="1">人员管理</el-menu-item>
        <el-menu-item index="2">部门管理</el-menu-item>
        <el-menu-item index="3">角色管理</el-menu-item>
        <el-menu-item index="4">字段管理</el-menu-item>
      </el-menu>
      <div class="Men_1">
        销售线索：① 管理系统目前所有的销售线索，可以进行编辑、删除、员工认领；② 可以通过本频道手动添加或批量上传销售线索，用于员工联系与跟踪
      </div>
    </div>

    <!------SSSSSSSSSSSSSSSSSSSSS----------人员管理---------------SSSSSSSSSSSSSSSSSSSSSSSSSSSSS------------->
    <div v-if="Index1 == true" style="margin-bottom:30px;overflow: hidden;">
      <div class="Table_left Tabless">
          <div class="uzi">
              <div class="el_seso" @click="D_sech" style="height: 30px;line-height: 31px;margin-top:4px"><i class="el-icon-search"></i></div>
              <div class="dsds">
                  <el-input  v-model="D_input" placeholder="请输入内容" @keyup.enter.native="D_sech()" style="width: 89%;"></el-input>
              </div>
          </div>
          <div class="custom-tree-container">
            <div class="block" style="margin-top:50px">
            <el-tree
              style="width:80%"
              class="kkkkkk"
              :data="DMent"
              node-key="id"
              :default-expand-all="false"
              :expand-on-click-node="true"
              @node-click="handleNodeClick"
            >
              <template #default="{ node, data }"> 
                <span class="custom-tree-node">
                  <span>{{ node.data.name }}</span>
                </span>

                <div @click.stop style="position: absolute;left:280px">
                  <el-dropdown trigger="click" @click="More(att.id)" style="margin-rignt:0">
                    <i class="Rund el-icon-more"></i>
                    <template #dropdown>
                      <el-dropdown-menu>
                        <el-dropdown-item command="a" @click.stop="bianji(data,node)">编辑</el-dropdown-item>
                        <el-dropdown-item command="a" @click.stop="append(data,node)">添加</el-dropdown-item>
                        <el-dropdown-item command="b"  @click.stop="remove(node, data)">删除</el-dropdown-item>
                      </el-dropdown-menu>
                    </template>
                  </el-dropdown>
                </div> 
              </template> 
            </el-tree>
            </div>
          </div>
      </div>
      <div class="Tabless" >
        <div class="Tab_T" @click="Add_kq(1)" v-if="TextMoMo('company:staff:add')"><i class="el-icon-plus"></i> 添加成员</div> 
        <div class=" Tab_T" style="background:#fff;border:1px solid #DCDFE6;color: #606266;" @click="Bian_bumen"><i class="el-icon-wallet"></i> 变更部门</div>
        <div class=" Tab_T" style="background:#fff;border:1px solid #DCDFE6;color: #606266;" @click="Resgin"><i class="el-icon-user"></i>已离职员工</div>
        <div style="float:right;width:30%">
          <div class="el_seso" @click="C_sech" style="height: 30px;line-height: 30px;margin-top:4px"><i class="el-icon-search"></i></div>
          <div class="dsds">
            <el-input stype="width:70%" v-model="C_input" placeholder="请输入内容" @keyup.enter.native="C_sech()"></el-input>
          </div>
        </div>
        <el-table
          :data="DData"
          class="basetable"
          ref="multipleTable"
          header-cell-class-name="basetable-header"
          row-key="id"
          :reserve-selection="true"
          @selection-change="Selection">
          <el-table-column
            type="selection"
            width="55"
            align="center"></el-table-column>
          <el-table-column
            prop="id"
            label="ID"
            width="60"
            align="center"></el-table-column>
          <el-table-column align="center" prop="realname" label="姓名" width="140"></el-table-column>
          <el-table-column align="center" prop="department.name" label="部门"  width="140"></el-table-column>
          <el-table-column align="center" prop="mobile" label="手机号"  width="170"></el-table-column>
          <el-table-column align="center" prop="job" label="职责"  width="140"></el-table-column>
          <el-table-column align="center" label="操作" >
            <template #default="scope">
                <div class="el_butto">  
                  <el-button type="text" @click="Staff_Show1(2, scope.row)" v-if="TextMoMo('company:staff:read')">查看</el-button>
                  <el-button type="text"  v-if="!TextMoMo('company:staff:read')">查看</el-button>
                </div>
                <div class="el_butto">  
                  <el-button type="text" @click="Staff_Show1(1, scope.row)" v-if="TextMoMo('company:staff:edit')">编辑</el-button>
                </div>
              <div class="el_butto rere">
                  <el-button 
                  type="text" v-if="TextMoMo('company:staff:del')"
                  class="red"
                  @click="Staff_del(scope.$index, scope.row)"
                  >删除</el-button>
              </div>
            </template>
          </el-table-column>
        </el-table>
        <div class="block">
          <el-pagination
            background
            @current-change="handleCurrentChange1"
            :current-page="currentPage1"
            :page-size="page.pageSize"
            layout="total, prev, pager, next, jumper"
            :total="total1">
          </el-pagination>
        </div>
      </div>
    </div>
    <!-------EEEEEEEEEEEEEEEEEEEE----------人员管理--------------EEEEEEEEEEEEEEEEEEEEEEEEEEEEEEE------------->

    <div  v-if="Index4">
      <div class="Tables2">
        <div class="Tab_T" @click="Add_kq(2)"><i class="el-icon-plus"></i>新增字段</div>
        <div style="float:right;width:30%">
          <div class="el_seso" ><i class="el-icon-search"></i></div>
          <div class="dsds">
            <el-input v-model="input" placeholder="请输入内容"></el-input>
          </div>
        </div>
        <el-table
          :data="ZMent"
          class="basetable"
          style="width: 100%"
          ref="multipleTable"
          header-cell-class-name="basetable-header"
          @selection-change="handleSelectionChange">
          <el-table-column
            type="selection"
            width="55"
            align="center"></el-table-column>
          <el-table-column
            prop="id"
            label="ID"
            width="60"
            align="center"></el-table-column>
          <el-table-column align="center" prop="title" label="字段名称" width="200"></el-table-column>
          <el-table-column align="center" prop="type_text" label="类型" width="190"></el-table-column>
          <el-table-column align="center" label="必填" width="180">
            <template #default="scope">
              <div>
                <el-switch
                  v-model="scope.row.is_require"
                  active-color="#1890FF"
                  inactive-color="#dcdfe6"
                  :active-value = 1
                  :inactive-value = 0
                  @change="Switch1(scope.row,)">
                </el-switch>
              </div>
            </template>
          </el-table-column>
          <el-table-column align="center" label="员工可见" width="180">
            <template #default="scope">
              <div>
                <el-switch
                  v-model="scope.row.is_show"
                  active-color="#1890FF"
                  inactive-color="#dcdfe6"
                  :active-value = 1
                  :inactive-value = 0
                  @change="Switch2(scope.row)">
                </el-switch>
              </div>
            </template>
          </el-table-column>
          <el-table-column align="center" label="员工可编辑" width="330">
            <template #default="scope">
              <div>
                <el-switch
                  v-model="scope.row.is_edit"
                  active-color="#1890FF"
                  inactive-color="#dcdfe6"
                  :active-value = 1
                  :inactive-value = 0
                  @change="Switch3(scope.row)">
                </el-switch>
              </div>
            </template>
          </el-table-column>
          <el-table-column label="操作" align="center" >
            <template #default="scope" >
                <div class="el_butto">
                  <el-button
                  type="text"
                  @click="Edit_xinz(scope.row)"
                  >编辑</el-button>
                </div>
                <div class="el_butto rere" >
                  <el-button style="background:#1890FF"
                  type="text"
                  class="red"
                  @click="Din_xinz(scope.row)"
                  v-if="scope.row.status == 'normal' "
                  >停用</el-button>
                <el-button style="background:#1890FF"
                  v-if="scope.row.status == 'hidden' "
                  type="text"
                  class="red"
                  @click="Din_xinz(scope.row)"
                  >启用</el-button>
                </div>
                <div class="el_butto rere" >
                    <el-button
                    type="text"
                    class="red"
                    @click="Del_xinz(scope.row)"
                    >删除</el-button>
                </div>
            </template>
          </el-table-column>
        </el-table>
        <div class="block">
          <el-pagination
            background
            @current-change="handleCurrentChange3"
            :current-page="currentPage3"
            :page-size="page.pageSize"
            layout="total, prev, pager, next, jumper"
            :total="total3">
          </el-pagination>
        </div>
      </div>      
    </div>
    <!-- 已离职员工 -->
    <div  v-if="Index5">
      <div class="Tables2">
        <div class="Tab_T" style="border: 1px solid #FF4D4F;color:#FF4D4F;background:#fff"  
          @click="Staff_del3"><i class="el-icon-delete"></i>批量删除</div>
        <div class="Tab_T" @click="Black"><i class="el-icon-back"></i>返回</div>
        <div style="float:right;width:23%">
          <div class="el_seso" style="height: 38px;line-height: 38px;"><i class="el-icon-search"></i></div>
          <div class="dsds">
            <el-input v-model="input" placeholder="请输入内容"></el-input>
          </div>
        </div>
        <el-table
          :data="ResginS"
          class="basetable"
          style="width: 100%"
          ref="multipleTable"
          header-cell-class-name="basetable-header"
          @selection-change="select_change">
          <el-table-column
            type="selection"
            width="55"
            align="center"></el-table-column>
          <el-table-column
            prop="id"
            label="ID"
            width="60"
            align="left"></el-table-column>
          <el-table-column prop="staff.realname" label="姓名"></el-table-column>
          <el-table-column prop="reason" label="部门"></el-table-column>
          <el-table-column prop="reason" label="职责"></el-table-column>
          <el-table-column prop="quit_time_text" label="离职时间"></el-table-column>
          <el-table-column prop="reason" label="离职类型"></el-table-column>
          <el-table-column label="操作" align="center" width="350" >
            <template #default="scope" >
                <div class="el_butto">
                  <el-button type="text" @click="Edit_lizhi(2, scope.row)" v-if="TextMoMo('company:quit:read')">查看</el-button>
                  <el-button type="text" v-if="!TextMoMo('company:quit:read')">查看</el-button>
                </div>
                <div class="el_butto">
                  <el-button type="text" @click="Edit_lizhi(1,scope.row)" v-if="TextMoMo('company:quit:edit')">编辑</el-button>
                </div>
                <div class="el_butto rere" >
                    <el-button
                    type="text" v-if="TextMoMo('company:quit:del')"
                    class="red"
                    @click="Staff_del2(scope.$index, scope.row)"
                    >删除</el-button>
                </div>
            </template>
          </el-table-column>
        </el-table>
        <div class="block">
          <el-pagination
            background
            @current-change="handleCurrentChange"
            :current-page="currentPage4"
            :page-size="page.pageSize"
            layout="total, prev, pager, next, jumper"
            :total="total2">
          </el-pagination>
        </div>
      </div>      
    </div>
    <!-- 已离职员工 -->

    <!--添加员工-->
    <div ref="rightPanel" v-if="show1" class="rightPanel-container show">
          <div class="rightPanel-background"/>
          <div style="height:1100px;width: 600px;position: fixed;top: 0;right: 0;z-index:999;" >
            <el-scrollbar style="height:100%">
              <div class="rightPanel">
                <div class="Rig_1" v-if="XX == 'xin'">添加员工<i class="el-icon-close" @click="Close" style="float:right;padding:20px 50px;cursor: pointer;"></i></div>
                <div class="Rig_1" v-if="XX == 'bj'">编辑员工<i class="el-icon-close" @click="Close" style="float:right;padding:20px 50px;cursor: pointer;"></i>
                 <span class="Rigjh" @click="Add_kq(3)">办理离职</span>
                </div>
                <div class="Rig_A">
                <div style="overflow:hidden">
                    <div style="width:50%;float:left">
                        <div class="Rig_D Rl_nput">
                            <div class="Rig_Ddd" style="margin-bottom:8px"> 
                              <span style="color:#FF4D4F">* </span>姓名</div>
                            <div class="Rtty">
                            <el-input v-model="Row.realname" placeholder="请输入成员姓名" class="El_ii"></el-input>
                            </div>
                        </div>
                    </div>
                    <div style="width:50%;float:left">
                        <div class="Rig_D Rl_nput">
                            <div class="Rig_Ddd" style="margin-bottom:8px"><span style="color:#FF4D4F">* </span> 手机号码</div>
                            <div class="Rtty">
                            <el-input v-model="Row.mobile" placeholder="请输入员工手机号码" class="El_ii"></el-input>
                            </div>
                        </div>
                    </div>
                </div>
                <div style="overflow:hidden">
                    <div style="width:50%;float:left">
                        <div class="Rig_D Rl_nput">
                            <div class="Rig_Ddd" style="margin-bottom:9px"><span style="color:#FF4D4F">* </span> 部门</div>
                            <div class="Rtty">
                                <el-cascader
                                style="width: 100%;"
                                ref="Casc"
                                v-model="Row_piii"
                                :options="DMent"
                                :change-on-select="true"
                                :props="{ value:'id', label:'name', checkStrictly: true }"
                                @change="handleChange"></el-cascader>
                            </div>
                        </div>
                    </div>
                    <div style="width:50%;float:left">
                        <div class="Rig_D Rl_nput">
                            <div class="Rig_Ddd" style="margin-bottom:9px"><span style="color:#FF4D4F">* </span> 职务</div>
                            <div class="Rtty">
                              <el-input v-model="Row.job" placeholder="请输入职务" class="El_ii"></el-input>
                            </div>
                        </div>
                    </div>
                </div>

                <div style="overflow:hidden">
                  <div style="width:50%;float:left">
                    <div class="Rig_D Rl_nput">
                      <div class="Rig_Ddd" style="margin-bottom:9px"><span style="color:#FF4D4F">* </span> 直属上级</div> 
                      <el-select v-model="Row.leader_id" placeholder="请选择">
                        <el-option v-for="item in DData22" :key="item.id" :label="item.realname" :value="item.id"></el-option>
                      </el-select>
                    </div>
                  </div>
                  <div style="width:50%;float:left">
                    <div class="Rig_D Rl_nput">
                      <div class="Rig_Ddd" style="margin-bottom:9px;width:100%">
                        <span style="color:#FF4D4F;float:left">* </span>
                        <span style="float:left;color:#333333!important">人员类型</span>
                        <span class="Peop" @click="dialogVisible1 = true">修改人员类型</span>
                      </div>
                      <el-select v-model="Fond_tag" placeholder="请选择" @change="Chang_ses2">
                        <el-option v-for="item in MMD" :key="item.value" :label="item.content" :value="item.value"></el-option>
                      </el-select>
                    </div>
                  </div>
                </div>
                <div style="overflow:hidden">
                    <div style="width:50%;float:left">
                        <div class="Rig_D Rl_nput">
                            <div class="Rig_Ddd" style="margin-bottom:8px">工号</div>
                            <div class="Rtty">
                            <el-input v-model="Row.job_number" placeholder="请填写员工工号，如：DF01001" class="El_ii"></el-input>
                            </div>
                        </div>
                    </div>
                    <div style="width:50%;float:left">
                        <div class="Rig_D Rl_nput">
                            <div class="Rig_Ddd" style="margin-bottom:8px">邮箱</div>
                            <div class="Rtty">
                            <el-input v-model="Row.email" placeholder="请填写成员的邮箱地址" class="El_ii"></el-input>
                            </div>
                        </div>
                    </div>
                </div>
                <div style="overflow:hidden">
                    <div style="width:50%;float:left">
                        <div class="Rig_D Rl_nput">
                            <div class="Rig_Ddd" style="margin-bottom:9px">入职时间</div>
                                <el-date-picker v-model="Row.entry_time" type="date" placeholder="选择日期" value-format="yyyy-MM-dd"></el-date-picker>
                        </div>
                    </div>
                    <div style="width:50%;float:left">
                        <div class="Rig_D Rl_nput">
                            <div class="Rig_Ddd" style="margin-bottom:9px;width:100%">性别</div>
                            <el-select v-model="Row.sex_text" placeholder="请选择">
                                <el-option v-for="item in sex_arr" :key="item.name" :value="item.id" :label="item.name"></el-option>
                            </el-select>
                        </div>
                    </div>
                </div>
                <div style="overflow:hidden">
                    <div style="width:50%;float:left">
                        <div class="Rig_D Rl_nput">
                            <div class="Rig_Ddd" style="margin-bottom:9px">角色</div>
                            <div class="Rtty">
                                <el-cascader
                                style="width: 100%;"
                                ref="Casc2"
                                v-model="Row_piii2"
                                :options="DMent2w"
                                :change-on-select="true"
                                :props="{ value:'id', label:'name', checkStrictly: true }"
                                @change="handleChange2"></el-cascader>
                            </div>
                        </div>
                    </div>
                </div>
                <div style="margin-bottom:20px;overflow: hidden;">
                  <div style="float:left">
                    <span style="margin-right:5px;font-size: 14px;font-weight: 400;color: #333333;">号码隐藏:</span>
                  </div>
                  <div style="margin-top:0px;float:left;margin-left:15px">
                    <el-radio v-model="Row.mobile_hide" :label= 1 >是</el-radio>
                    <el-radio v-model="Row.mobile_hide" :label= 0 >否</el-radio>
                  </div>
                </div>
                
                <div>
                  <div class="Rig_Ddd" style="margin-bottom:9px">照片上传：</div>
                  <div class="Rtty" style="clear:both">
              
                    <img v-if="Row.full_avatar && aTY == 1" :src="Row.full_avatar" class="avatar" />
                    <el-upload
                      :action="upload"
                      :headers="heads"
                      list-type="picture-card"
                      :on-success="UP_Success"
                      :on-preview="handlePictureCardPreview"
                      :on-remove="handleRemove">
                      <i class="el-icon-plus"></i>
                      <div class="el_asff">上传头像</div>
                    </el-upload>
                  </div>
                </div>
                <div class="BCSZ" v-if="XX == 'xin'"  @click="Staff_Add(1)" style="margin-right:24px">完成并添加</div>
                <div class="BCSZ" v-if="XX == 'bj'"  @click="Staff_Add(2)" style="margin-right:24px">完成编辑</div>
                <div class="BCSZ"  style="background:#fff;color:#595959;border: 1px solid #D9D9D9;" @click="show1 = false">取消</div>

                </div>
              </div>
            </el-scrollbar>
          </div>
    </div>
    <!--添加员工-->

    <!--查看员工-->
    <div ref="rightPanel" v-if="show11" class="rightPanel-container show">
          <div class="rightPanel-background"/>
          <div style="height:1100px;width: 600px;position: fixed;top: 0;right: 0;z-index:999;" >
            <el-scrollbar style="height:100%">
              <div class="rightPanel">
                <div class="Rig_1">查看员工<i class="el-icon-close" @click="Close" style="float:right;padding:20px 50px;cursor: pointer;"></i></div> 
                <div class="Rig_A">
                <div style="overflow:hidden">
                    <div style="width:50%;float:left">
                        <div class="Rig_D Rl_nput">
                            <div class="Rig_Ddd" style="margin-bottom:8px"> 
                              <span style="color:#FF4D4F">* </span>姓名</div>
                            <div class="Rtty">
                             <el-input v-model="Row.realname" placeholder="请输入成员姓名" class="El_ii" disabled></el-input>
                            </div>
                        </div>
                    </div>
                    <div style="width:50%;float:left">
                        <div class="Rig_D Rl_nput">
                            <div class="Rig_Ddd" style="margin-bottom:8px"><span style="color:#FF4D4F">* </span> 手机号码</div>
                            <div class="Rtty">
                              <el-input v-model="Row.mobile" placeholder="请输入员工手机号码" class="El_ii" disabled></el-input>
                            </div>
                        </div>
                    </div>
                </div>
                <div style="overflow:hidden">
                    <div style="width:50%;float:left">
                        <div class="Rig_D Rl_nput">
                            <div class="Rig_Ddd" style="margin-bottom:9px"><span style="color:#FF4D4F">* </span> 部门</div>
                            <div class="Rtty">
                                <el-cascader
                                style="width: 100%;"
                                ref="Casc" disabled
                                v-model="Row_piii"
                                :options="DMent"
                                :change-on-select="true"
                                :props="{ value:'id', label:'name', checkStrictly: true }"
                                @change="handleChange"></el-cascader>
                            </div>
                        </div>
                    </div>
                    <div style="width:50%;float:left">
                        <div class="Rig_D Rl_nput">
                            <div class="Rig_Ddd" style="margin-bottom:9px"><span style="color:#FF4D4F">* </span> 职务</div>
                            <div class="Rtty">
                              <el-input v-model="Row.job" placeholder="请输入职务" class="El_ii" disabled></el-input>
                            </div>
                        </div>
                    </div>
                </div>
                <div style="overflow:hidden">
                    <div style="width:50%;float:left">
                        <div class="Rig_D Rl_nput">
                            <div class="Rig_Ddd" style="margin-bottom:9px"><span style="color:#FF4D4F">* </span> 直属上级</div>
                            <el-select v-model="Row.leader_id" placeholder="请选择" disabled>
                                <el-option v-for="item in DData22" :key="item.id" :label="item.realname" :value="item.id"></el-option>
                            </el-select>
                        </div>
                    </div>
                    <div style="width:50%;float:left">
                        <div class="Rig_D Rl_nput">
                            <div class="Rig_Ddd" style="margin-bottom:9px;width:100%">
                              <span style="color:#FF4D4F;float:left">* </span>
                              <span style="float:left;color:#333333!important">人员类型</span>
                              <span class="Peop" @click="dialogVisible1 = true">修改人员类型</span>
                            </div>
                            <el-select v-model="Fond_tag" placeholder="请选择" @change="Chang_ses2" disabled>
                                <el-option v-for="item in MMD" :key="item.value" :label="item.content" :value="item.value"></el-option>
                            </el-select>
                        </div>
                    </div>
                </div>
                <div style="overflow:hidden">
                    <div style="width:50%;float:left">
                        <div class="Rig_D Rl_nput">
                            <div class="Rig_Ddd" style="margin-bottom:8px">工号</div>
                            <div class="Rtty">
                              <el-input v-model="Row.job_number" placeholder="请填写员工工号，如：DF01001" class="El_ii" disabled></el-input>
                            </div>
                        </div>
                    </div>
                    <div style="width:50%;float:left">
                        <div class="Rig_D Rl_nput">
                            <div class="Rig_Ddd" style="margin-bottom:8px">邮箱</div>
                            <div class="Rtty">
                              <el-input v-model="Row.email" placeholder="请填写成员的邮箱地址" class="El_ii" disabled></el-input>
                            </div>
                        </div>
                    </div>
                </div>
                <div style="overflow:hidden">
                    <div style="width:50%;float:left">
                        <div class="Rig_D Rl_nput">
                            <div class="Rig_Ddd" style="margin-bottom:9px">入职时间</div>
                                <el-date-picker v-model="Row.entry_time" type="date" disabled placeholder="选择日期" value-format="yyyy-MM-dd"></el-date-picker>
                        </div>
                    </div>
                    <div style="width:50%;float:left">
                        <div class="Rig_D Rl_nput">
                            <div class="Rig_Ddd" style="margin-bottom:9px;width:100%">性别</div>
                            <el-select v-model="Row.sex_text" placeholder="请选择" disabled>
                                <el-option v-for="item in sex_arr" :key="item.name" :value="item.id" :label="item.name"></el-option>
                            </el-select>
                        </div>
                    </div>
                </div>
                <div style="overflow:hidden">
                    <div style="width:50%;float:left">
                        <div class="Rig_D Rl_nput">
                            <div class="Rig_Ddd" style="margin-bottom:9px">角色</div>
                            <div class="Rtty">
                                <el-cascader 
                                style="width: 100%;"
                                ref="Casc2" disabled
                                v-model="Row_piii2"
                                :options="DMent2w"
                                :change-on-select="true"
                                :props="{ value:'id', label:'name', checkStrictly: true }"
                                @change="handleChange2"></el-cascader>
                            </div>
                        </div>
                    </div>
                </div>
                <div style="margin-bottom:20px;overflow: hidden;">
                  <div style="float:left">
                    <span style="margin-right:5px;font-size: 14px;font-weight: 400;color: #333333;">号码隐藏:</span>
                  </div>
                  <div style="margin-top:0px;float:left;margin-left:15px">
                    <el-radio v-model="Row.mobile_hide" :label= 1 disabled>是</el-radio>
                    <el-radio v-model="Row.mobile_hide" :label= 0 disabled>否</el-radio>
                  </div>
                </div>
                
                <div>
                  <div class="Rig_Ddd" style="margin-bottom:9px">照片上传：</div>
                  <div class="Rtty" style="clear:both">
              
                    <img v-if="Row.full_avatar && aTY == 1" :src="Row.full_avatar" class="avatar" />
                  </div>
                </div>
                <div class="BCSZ"  style="background:#fff;color:#595959;border: 1px solid #D9D9D9;" @click="show1 = false">取消</div>

                </div>
              </div>
            </el-scrollbar>
          </div>
    </div>
    <!--查看员工-->

    <!-- 办理离职 -->
    <div ref="rightPanel" v-if="show3" class="rightPanel-container show">
          <div class="rightPanel-background"/>
          <div style="height:1100px;width: 600px;position: fixed;top: 0;right: 0;z-index:999;" >
            <el-scrollbar style="height:100%">
              <div class="rightPanel">
                <div class="Rig_1">办理离职<i class="el-icon-close" @click="Close" style="float:right;padding:20px 50px;cursor: pointer;"></i></div>
                <div class="Rig_A">
                <div class="TY_iq">
                  <div>{{staffInfo.realname}} （豆腐网）</div>
                </div>
                <div style="overflow:hidden">
                    <div style="width:50%;float:left">
                        <div class="Rig_D Rl_nput">
                            <div class="Rig_Ddd" style="margin-bottom:9px">离职原因</div>
                            <el-input v-model="Row2.reason" placeholder="请选择离职原因" ></el-input>
                        </div>
                    </div>
                    <div style="width:50%;float:left">
                        <div class="Rig_D Rl_nput">
                            <div class="Rig_Ddd" style="margin-bottom:9px">离职类型</div>
                            <el-select v-model="Row2.type_text" placeholder="请选择" @change="Select_typ">
                              <el-option v-for="item in options1" :key="item.id" :label="item.name" :value="item.name"></el-option>
                            </el-select>
                        </div>
                    </div>
                </div>
                <div style="overflow:hidden">
                    <div style="width:50%;float:left">
                        <div class="Rig_D Rl_nput">
                            <div class="Rig_Ddd" style="margin-bottom:9px">办理状态</div>
                            <el-select v-model="Row2.quit_status_text" placeholder="请选择" @change="Select_quit">
                              <el-option v-for="item in options2" :key="item.id" :label="item.name" :value="item.name"></el-option>
                            </el-select>
                        </div>
                    </div>
                    <div style="width:50%;float:left">
                        <div class="Rig_D Rl_nput">
                            <div class="Rig_Ddd" style="margin-bottom:9px;width:100%">
                              <span style="float:left;color:#333333!important">离职时间</span>
                            </div>
                            <el-date-picker v-model="Row2.quit_time_text" type="date" placeholder="选择日期"></el-date-picker>
                        </div>
                    </div>
                </div>

                <div style="margin-bottom:20px;overflow:hidden">
                  <div style="float:left">
                    <span style="margin-right:5px">将员工拥有的资源：</span>
                  </div>
                  <div style="float:left;margin-left:10px">
                    <el-radio-group v-model="Row2.is_transfer">
                      <el-radio :label="0">直接删除</el-radio>
                      <el-radio :label="1">转移资源给其他员工</el-radio>
                    </el-radio-group>
                  </div>
                </div>
                <div style="width:95%">
                <el-table
                  :data="Row2.transfer"
                  class="basetable"
                  style="width: 100%"
                  ref="multipleTable"
                  header-cell-class-name="basetable-header">
                  <el-table-column prop="name" label="资源"></el-table-column>
                  <el-table-column prop="people" label="描述"></el-table-column>
                  <el-table-column label="接收人">
                    <template #default="scope">
                      <div class="fgfhsdg">
                        <div v-for="(item,i) in Row2.transfer" :key="i" >
                          <div v-if="item.to_staff != null ">
                            <el-input v-if="i == scope.$index" v-model="item.to_staff.realname" placeholder="选择接收人" @focus='Chang_ipu(scope.$index)'></el-input>
                          </div>
                          <div v-if="item.to_staff == null ">
                            <el-input v-if="i == scope.$index" v-model="RRealname" placeholder="选择接收人" disabled></el-input>
                          </div>
                        </div>
                        
                      </div>
                    </template>
                  </el-table-column>
                </el-table>
                </div>
                <div class="BCSZ" @click="BTN_lizhi" style="margin-right:24px">保存设置</div>
                </div>
              </div>
            </el-scrollbar>
          </div>
    </div>
    <!-- 办理离职 -->

    <!--查看离职 -->
    <div ref="rightPanel" v-if="show33" class="rightPanel-container show">
          <div class="rightPanel-background"/>
          <div style="height:1100px;width: 600px;position: fixed;top: 0;right: 0;z-index:999;" >
            <el-scrollbar style="height:100%">
              <div class="rightPanel" >
                <div class="Rig_1">查看离职<i class="el-icon-close" @click="Close" style="float:right;padding:20px 50px;cursor: pointer;"></i></div>
                <div class="Rig_A">
                <div class="TY_iq">
                  <img :src="staffInfo.full_avatar" alt="">
                  <div>{{staffInfo.realname}} （豆腐网）</div>
                </div>
                <div style="overflow:hidden">
                    <div style="width:50%;float:left">
                        <div class="Rig_D Rl_nput">
                            <div class="Rig_Ddd" style="margin-bottom:9px">离职原因</div>
                            <el-input v-model="Row2.reason" placeholder="请选择离职原因" disabled ></el-input>
                        </div>
                    </div>
                    <div style="width:50%;float:left">
                        <div class="Rig_D Rl_nput">
                            <div class="Rig_Ddd" style="margin-bottom:9px">离职类型</div>
                            <el-select v-model="Row2.type_text" placeholder="请选择" @change="Select_typ" disabled>
                              <el-option v-for="item in options1" :key="item.id" :label="item.name" :value="item.name"></el-option>
                            </el-select>
                        </div>
                    </div>
                </div>
                <div style="overflow:hidden">
                    <div style="width:50%;float:left">
                        <div class="Rig_D Rl_nput">
                            <div class="Rig_Ddd" style="margin-bottom:9px">办理状态</div>
                            <el-select v-model="Row2.quit_status_text" placeholder="请选择" @change="Select_quit" disabled>
                              <el-option v-for="item in options2" :key="item.id" :label="item.name" :value="item.name"></el-option>
                            </el-select>
                        </div>
                    </div>
                    <div style="width:50%;float:left">
                        <div class="Rig_D Rl_nput">
                            <div class="Rig_Ddd" style="margin-bottom:9px;width:100%">
                              <span style="float:left;color:#333333!important">离职时间</span>
                            </div>
                            <el-date-picker v-model="Row2.quit_time_text" type="date" placeholder="选择日期" disabled></el-date-picker>
                        </div>
                    </div>
                </div>

                <div style="margin-bottom:20px;overflow:hidden">
                  <div style="float:left">
                    <span style="margin-right:5px">将员工拥有的资源：</span>
                  </div>
                  <div style="float:left;margin-left:10px">
                    <el-radio-group v-model="Row2.is_transfer">
                      <el-radio :label="0" disabled>直接删除</el-radio>
                      <el-radio :label="1" disabled>转移资源给其他员工</el-radio>
                    </el-radio-group>
                  </div>
                </div>

                <div style="width: 95%;"> 
                  <el-table
                    :data="Row2.transfer"
                    class="basetable"
                    style="width: 100%"
                    ref="multipleTable"
                    header-cell-class-name="basetable-header">
                    <el-table-column prop="name" label="资源"></el-table-column>
                    <el-table-column prop="people" label="描述"></el-table-column>
                    <el-table-column label="接收人">
                      <template #default="scope">
                        <div class="fgfhsdg">
                          <div v-for="(item,i) in Row2.transfer" :key="i" >
                            <div v-if="item.to_staff != null ">
                              <el-input v-if="i == scope.$index" v-model="item.to_staff.realname" placeholder="选择接收人" disabled @focus='Chang_ipu(scope.$index)'></el-input>
                            </div>
                            <div v-if="item.to_staff == null ">
                              <el-input v-if="i == scope.$index" v-model="RRealname" placeholder="选择接收人" disabled></el-input>
                            </div>
                          </div>

                        </div>
                      </template>
                    </el-table-column>
                  </el-table>
                </div>
                <div class="BCSZ" style="background:#fff;color:#595959;border: 1px solid #D9D9D9;">取消</div>
                </div>
              </div>
            </el-scrollbar>
          </div>
    </div>
    <!-- 查看离职 -->    

    <!--确认删除-->
    <el-dialog title="确认删除" v-model="dia_DEl" width="31%">
      <div class="Dityo">
        <div class="dialog-footer">
          <el-button @click="dia_DEl = false">取 消</el-button>
          <el-button type="primary" @click="Del_Sure">确 定</el-button>
        </div>
      </div>  

    </el-dialog>
    <!--确认删除-->

    <!--修改人员类型-->
    <el-dialog title="修改人员类型" v-model="dialogVisible1" width="36%"> 
      <div class="Tabbl">
        <el-table
        :data="Categ"
        class="basetable"
        ref="multipleTable"
        header-cell-class-name="basetable-header"
        @selection-change="handleSelectionChange">
        <el-table-column label="选项类别" width="240" align="center" >
          <template #default="scope" >
              <div class="El_pop dasdasd">
                <el-input :disabled = "scope.row.type == 'default' ? true : false"  v-model="scope.row.content" placeholder="类别"></el-input>
              </div>
          </template>
        </el-table-column>
        <el-table-column label="设置" width="180" align="center">
            <template #default="scope">
            <div v-if="scope.row.type == 'default'">预设</div>
            <div v-if="scope.row.type == 'custom'">自定义</div>
            </template>
        </el-table-column>
        <el-table-column label="是否启用" width="96">
          <template #default="scope" >
            <div class="el_butto rere dsdtrt">
                <el-switch
                  :disabled="scope.row.type == 'default' ? true : false "
                  v-model="scope.row.status"
                  active-value = 'normal'
                  inactive-value = 'hidden'
                  active-color="#1890FF"
                  @change="changeSwitch($event,scope.row,scope.$index)"
                  inactive-color="#dcdfe6">
                </el-switch>
            </div>
          </template>
        </el-table-column>
        <el-table-column label="操作" width="100" align="center" >
          <template #default="scope" >
            <div class="el_butto rere dsdtrt">
                <i class="el-icon-delete" @click="handleDelete(scope.$index, scope.row)"></i>
            </div>
          </template>
        </el-table-column>
      </el-table>
      </div>
      <div class="Y_ids" @click="Add_leibie"><i class="el-icon-plus"></i>新增选项</div>
      <div class="Dityo">
        <div class="dialog-footer">
          <el-button @click="dialogVisible1 = false">取 消</el-button>
          <el-button type="primary" @click="NabCH_BTN" style="margin-left:15px">确 定</el-button>
        </div>
      </div>  

    </el-dialog>
    <!--修改人员类型-->

    <!--新增字段-->
    <el-dialog title="新增/编辑字段" v-model="dialogVisible2" width="32%">
      <div class="Xians">
        <span class="isddss" style="margin-left:28px"><span style="color:#FF4D4F">* </span>字段名称：</span>
        <el-input v-model="Row3.name" placeholder="请输入字段名称"></el-input>
      </div>
      <div class="Xians">
        <span class="isddss" style="margin-left:28px"><span style="color:#FF4D4F">* </span>字段标题：</span>
        <el-input v-model="Row3.title" placeholder="请输入字段标题"></el-input>
      </div>
      <div class="isdd">
        <span class="isddss">字段类型：</span>
          <el-select v-model="Type_List_text" placeholder="请选择" @change="Type_Chan">
              <el-option v-for="item in Type_List" :key="item" :label="item" :value="item"></el-option>
          </el-select>
        <!-- <el-input v-model="Row3.type" placeholder="请选择字段类型，默认为文本"></el-input> -->
      </div>
      <div class="Xians isdd" style="margin-left:14px">
        <span class="isddss">提示语：</span>
        <el-input v-model="Row3.tip" placeholder="请输入备注文字，以让人很好理解如何录入"></el-input>
      </div>
      <div class="Xians isdd dsdsd" style="margin-bottom:4px">
        <span class="isddss">选项内容：</span>
        <!-- <el-input v-model="Row3.content" placeholder="选项一："></el-input> -->
        <div style="overflow:hidden">
          <el-input v-for="(item,i) in R3_inou" :key="i" v-model="item.content" :placeholder="item.name"></el-input>
        </div>

      </div>
      <div class="Icci" @click="Add_cent">
        <i class="el-icon-circle-plus-outline"></i><span>添加选项内容</span>
      </div>
      <div class="Xians isdd" style="margin-left:14px">
        <span class="dwwwasd">是否为必填项：</span>
        <div>
            <el-radio v-model="Row3.is_require" :label="0">必填项</el-radio>
            <el-radio v-model="Row3.is_require" :label="1">必填项</el-radio>
        </div>
      </div>
      <div class="Xians isdd" style="margin-left:14px">
        <span class="dwwwasd">员工是否可见：</span>
        <div>
            <el-radio v-model="Row3.is_show" :label="0">员工可见</el-radio>
            <el-radio v-model="Row3.is_show" :label="1">员工不可见</el-radio>
        </div>
      </div>
      <div class="Xians isdd" style="margin-left:0px">
        <span class="dwwwasd">员工是否可编辑：</span>
        <div>
            <el-radio v-model="Row3.is_edit" :label="0">员工可编辑</el-radio>
            <el-radio v-model="Row3.is_edit" :label="1">员工不可编辑</el-radio>
        </div>
      </div>
      <div class="Dityo">
        <div class="dialog-footer">
          <el-button @click="dialogVisible2 = false">取 消</el-button>
          <el-button type="primary" @click="BTN_ziduan(1)" v-if="Ad2_ziduan == '' " style="margin-left:15px">保存设置</el-button>
          <el-button type="primary" @click="BTN_ziduan(2)" v-if="Ad2_ziduan == 'xin' " style="margin-left:15px">确认新增</el-button>
        </div>
      </div>  

    </el-dialog>
    <!--新增字段-->

    <!--选择成员-->
    <el-dialog title="选择成员" v-model="dialogVisible5" width="48.5%"> 
      <div class="Trtfd">
        <div class="Tytt">
          <div class="hhhh">
            <div class="dsds" style="float:left">
              <el-input v-model="input" placeholder="请输入内容"></el-input>
            </div>
            <div class="el_seso"><i class="el-icon-search"></i></div>
          </div>
          <div class="Blacks" >
              <!-- <div>
                豆腐网
              </div> -->
              <div v-for="item in Parents" :key="item.id" @click="Bloo_Black(item.id)" style="cursor: pointer;">
                {{item.name}} <span v-if="Parents.length > 1">&nbsp;/&nbsp;</span>
              </div>
            </div>
          <div class="custom-tree-container">
            <div class="block" style="margin-top:16px;margin-left:15px">
            <div class="Blo_a" v-for="item in DepartmentList" :key="item.id">
              <div>
                <img class="Blo_a1" src="" alt="">
                <span class="Blo_a2">{{item.name}}</span>
              </div>
              <div @click="Nice_x(item.id)" style="cursor: pointer;">
                <img class="Blo_a3" src="" alt="">
                <span class="Blo_a4">下级</span>
              </div>
            </div>
            <!-- <div  > 

            </div> -->
            <div>
              <el-checkbox-group v-model="checkList" @change="bindCheckBox">
                <div class="Blo_b" v-for="item in StaffList" :key="item.id">
                  <el-checkbox :label="item.id">
                    <img :src="item.full_avatar" alt="">
                    <span>{{item.realname}}</span>
                    </el-checkbox>
                </div>                 
              </el-checkbox-group>
            </div>
            </div>
          </div>
        </div>
        <div class="Tytt" style="padding:29px 0px 20px 20px;">
          <div v-if="Chan_Tonx != null">已选：1个员工</div>
          <div v-if="Chan_Tonx == null">已选：0个员工</div>
          <div class="tyhh" v-if="Chan_Tonx != null">
            <img :src="Chan_Tonx.full_avatar" alt="">
            <span>{{Chan_Tonx.realname}}</span>
            <i class="el-icon-close" @click="Close" style="float:right;padding:20px 50px;cursor: pointer;"></i>
          </div>
        </div>

      </div>
         <div class="Dityo">
          <div class="dialog-footer">
            <!-- <el-button @click="Close_2">取 消</el-button> -->
            <el-button type="primary" @click="Close_3" style="margin-left:15px">保存设置</el-button>
          </div>
        </div> 

    </el-dialog>
    <!--选择成员-->

    <!--变更部门-->
    <el-dialog title="变更部门" v-model="dialogVisible6" width="48.5%"> 
      <div class="Trtfd">
        <div class="Tytt">
          <div class="hhhh">
            <div class="dsds" style="float:left">
              <el-input v-model="input" placeholder="请输入内容"></el-input>
            </div>
            <div class="el_seso"><i class="el-icon-search"></i></div>
          </div>
          <div class="Blacks" >
              <!-- <div>
                豆腐网
              </div> -->
              <div v-for="item in Parents" :key="item.id" @click="Bloo_Black(item.id)" style="cursor: pointer;">
                {{item.name}} <span v-if="Parents.length > 1">&nbsp;/&nbsp;</span>
              </div>
            </div>
          <div class="custom-tree-container">
            <div class="block" style="margin-top:16px;margin-left:15px">
            <el-tree
              :data="DMent"
              node-key="code"
              ref="tree"
              check-strictly
              show-checkbox
              :default-expand-all="false"
              :expand-on-click-node="false"
              @check-change="handleClick"
            >
              <template #default="{ node, data }">
                <span class="custom-tree-node">
                  <span>{{ node.data.name }}</span>
                </span>
              </template>

            </el-tree>

            </div>
          </div>
        </div>
        <div class="Tytt" style="padding:29px 0px 20px 20px;">
          <div v-if="Bumen_Tonx != null">已选：1个部门</div>
          <div v-if="Bumen_Tonx == null">已选：0个部门</div>
          <div class="tyhh" v-if="Bumen_Tonx != null">
            <!-- <img :src="Bumen_Tonx.full_avatar" alt=""> -->
            <span>{{Bumen_Tonx.name}}</span>
            <i class="el-icon-close" @click="Close_Bumen" style="float:right;padding:20px 50px;cursor: pointer;"></i>
          </div>
        </div>

      </div>
         <div class="Dityo">
          <div class="dialog-footer">
            <!-- <el-button @click="Close_2">取 消</el-button> -->
            <el-button type="primary" @click="BTN_Bumen" style="margin-left:15px">保存设置</el-button>
          </div>
        </div> 

    </el-dialog>
    <!--变更部门-->

    <!--选择部门-->
    <el-dialog title="选择部门" v-model="dialogVisible7" width="48.5%"> 
      <div class="Trtfd">
        <div class="Tytt">
          <div class="hhhh">
            <div class="dsds" style="float:left">
              <el-input v-model="input" placeholder="请输入内容"></el-input>
            </div>
            <div class="el_seso"><i class="el-icon-search"></i></div>
          </div>
          <div class="Blacks" >
              <!-- <div>
                豆腐网
              </div> -->
              <div v-for="item in Parents" :key="item.id" @click="Bloo_Black(item.id)" style="cursor: pointer;">
                {{item.name}} <span v-if="Parents.length > 1">&nbsp;/&nbsp;</span>
              </div>
            </div>
          <div class="custom-tree-container">
            <div class="block" style="margin-top:16px;margin-left:15px">
            <el-tree
              :data="DMent"
              node-key="code"
              ref="tree"
              check-strictly
              show-checkbox
              :default-expand-all="false"
              :expand-on-click-node="false"
              @check-change="handleClick2"
            >
              <template #default="{ node, data }">
                <span class="custom-tree-node">
                  <span>{{ node.data.name }}</span>
                </span>
              </template>

            </el-tree>

            </div>
          </div>
        </div>
        <div class="Tytt" style="padding:29px 0px 20px 20px;">
          <div v-if="Bumen_Tonx2 != null">已选：1个部门</div>
          <div v-if="Bumen_Tonx2 == null">已选：0个部门</div>
          <div class="tyhh" v-if="Bumen_Tonx2 != null">
            <!-- <img :src="Bumen_Tonx.full_avatar" alt=""> -->
            <span>{{Bumen_Tonx2.name}}</span>
            <i class="el-icon-close" @click="Close_Bumen" style="float:right;padding:20px 50px;cursor: pointer;"></i>
          </div>
        </div>

      </div>
         <div class="Dityo">
          <div class="dialog-footer">
            <!-- <el-button @click="Close_2">取 消</el-button> -->
            <el-button type="primary" @click="BTN_Bumen3" style="margin-left:15px">保存设置</el-button>
          </div>
        </div> 

    </el-dialog>
    <!--选择部门-->


    <!--选择直属上级成员-->
    <el-dialog title="选择上级" v-model="dialogVisible8" width="48.5%"> 
      <div class="Trtfd">
        <div class="Tytt">
          <div class="hhhh">
            <div class="dsds" style="float:left">
              <el-input v-model="input" placeholder="请输入内容"></el-input>
            </div>
            <div class="el_seso"><i class="el-icon-search"></i></div>
          </div>
          <div class="Blacks" >
              <!-- <div>
                豆腐网
              </div> -->
              <div v-for="item in Parents" :key="item.id" @click="Bloo_Black(item.id)" style="cursor: pointer;">
                {{item.name}} <span v-if="Parents.length > 1">&nbsp;/&nbsp;</span>
              </div>
            </div>
          <div class="custom-tree-container">
            <div class="block" style="margin-top:16px;margin-left:15px">
            <div class="Blo_a" v-for="item in DepartmentList" :key="item.id">
              <div>
                <img class="Blo_a1" src="" alt="">
                <span class="Blo_a2">{{item.name}}</span>
              </div>
              <div @click="Nice_x(item.id)" style="cursor: pointer;">
                <img class="Blo_a3" src="" alt="">
                <span class="Blo_a4">下级</span>
              </div>
            </div>
            <!-- <div  >

            </div> -->
            <div>
              <el-checkbox-group v-model="checkList" @change="bindCheckBox">
                <div class="Blo_b" v-for="item in StaffList" :key="item.id">
                  <el-checkbox :label="item.id">
                    <img :src="item.full_avatar" alt="">
                    <span>{{item.realname}}</span>
                    </el-checkbox>
                </div>                 
              </el-checkbox-group>
            </div>
            </div>
          </div>
        </div>
        <div class="Tytt" style="padding:29px 0px 20px 20px;">
          <div v-if="Chan_Tonx2 != null">已选：1个员工</div>
          <div v-if="Chan_Tonx2 == null">已选：0个员工</div>
          <div class="tyhh" v-if="Chan_Tonx2 != null">
            <img :src="Chan_Tonx2.full_avatar" alt="">
            <span>{{Chan_Tonx2.realname}}</span>
            <i class="el-icon-close" @click="Close" style="float:right;padding:20px 50px;cursor: pointer;"></i>
          </div>
        </div>

      </div>
         <div class="Dityo">
          <div class="dialog-footer">
            <!-- <el-button @click="Close_2">取 消</el-button> -->
            <el-button type="primary" @click="Close_4" style="margin-left:15px">保存设置</el-button>
          </div>
        </div> 

    </el-dialog>
    <!--选择直属上级成员-->

    <!--部门编辑修改名称-->
    <el-dialog title="修改部门名称" v-model="dialogVisible9" width="24.5%">
      <div>
        <el-input v-model="diasible9" placeholder=""></el-input>
      </div>
      <div class="Dityo">
        <div class="dialog-footer" style="padding-right:28px">
          <el-button @click="dialogVisible9 = false">取 消</el-button>
          <el-button type="primary" @click="Btn_xiugai" style="margin-left:15px">保存</el-button>
        </div>
      </div>  
    </el-dialog>
    <!--部门编辑修改名称-->  

    <!--选择上级部门-->
    <el-dialog title="选择上级部门" v-model="dialogVisible10" width="48.5%"> 
      <div class="Trtfd">
        <div class="Tytt">
          <div class="hhhh">
            <div class="dsds" style="float:left">
              <el-input v-model="input" placeholder="请输入内容"></el-input>
            </div>
            <div class="el_seso"><i class="el-icon-search"></i></div>
          </div>
          <div class="Blacks" >
              <!-- <div>
                豆腐网
              </div> -->
              <div v-for="item in Parents" :key="item.id" @click="Bloo_Black(item.id)" style="cursor: pointer;">
                {{item.name}} <span v-if="Parents.length > 1">&nbsp;/&nbsp;</span>
              </div>
            </div>
          <div class="custom-tree-container">
            <div class="block" style="margin-top:16px;margin-left:15px">
            <el-tree
              :data="DMent"
              node-key="code"
              ref="tree"
              check-strictly
              show-checkbox
              :default-expand-all="false"
              :expand-on-click-node="false"
              @check-change="Bmen1_Chan"
            >
              <template #default="{ node, data }">
                <span class="custom-tree-node">
                  <span>{{ node.data.name }}</span>
                </span>
              </template>

            </el-tree>

            </div>
          </div>
        </div>
        <div class="Tytt" style="padding:29px 0px 20px 20px;">
          <div v-if="Bumen_Xinj1 != null">已选：1个部门</div>
          <div v-if="Bumen_Xinj1 == null">已选：0个部门</div>
          <div class="tyhh" v-if="Bumen_Xinj1 != null">
            <!-- <img :src="Bumen_Tonx.full_avatar" alt=""> -->
            <span>{{Bumen_Xinj1.name}}</span>
            <i class="el-icon-close" @click="Close_Bumen" style="float:right;padding:20px 50px;cursor: pointer;"></i>
          </div>
        </div>

      </div>
         <div class="Dityo">
          <div class="dialog-footer">
            <!-- <el-button @click="Close_2">取 消</el-button> -->
            <el-button type="primary" @click="BTN_Bumen2" style="margin-left:15px">保存设置</el-button>
          </div>
        </div> 

    </el-dialog>
    <!--选择上级部门-->

    <!--选择部门负责人-->
    <el-dialog title="选择部门负责人" v-model="dialogVisible11" width="48.5%"> 
      <div class="Trtfd">
        <div class="Tytt">
          <div class="hhhh">
            <div class="dsds" style="float:left">
              <el-input v-model="input" placeholder="请输入内容"></el-input>
            </div>
            <div class="el_seso"><i class="el-icon-search"></i></div>
          </div>
          <div class="Blacks" >
              <!-- <div>
                豆腐网
              </div> -->
              <div v-for="item in Parents" :key="item.id" @click="Bloo_Black(item.id)" style="cursor: pointer;">
                {{item.name}} <span v-if="Parents.length > 1">&nbsp;/&nbsp;</span>
              </div>
            </div>
          <div class="custom-tree-container">
            <div class="block" style="margin-top:16px;margin-left:15px">
            <div class="Blo_a" v-for="item in DepartmentList" :key="item.id">
              <div>
                <img class="Blo_a1" src="" alt="">
                <span class="Blo_a2">{{item.name}}</span>
              </div>
              <div @click="Nice_x(item.id)" style="cursor: pointer;">
                <img class="Blo_a3" src="" alt="">
                <span class="Blo_a4">下级</span>
              </div>
            </div>
            <!-- <div  >

            </div> -->
            <div>
              <el-checkbox-group v-model="checkList2" @change="bindCheckBox2">
                <div class="Blo_b" v-for="item in StaffList" :key="item.id">
                  <el-checkbox :label="item.id">
                    <img :src="item.full_avatar" alt="">
                    <span>{{item.realname}}</span>
                    </el-checkbox>
                </div>                 
              </el-checkbox-group>
            </div>
            </div>
          </div>
        </div>
        <div class="Tytt" style="padding:29px 0px 20px 20px;">
          <div v-if="Bumen_Xinj2 != null">已选：1个员工</div>
          <div v-if="Bumen_Xinj2 == null">已选：0个员工</div>
          <div class="tyhh" v-if="Bumen_Xinj2 != null">
            <img :src="Bumen_Xinj2.full_avatar" alt="">
            <span>{{Bumen_Xinj2.realname}}</span>
            <i class="el-icon-close" @click="Close" style="float:right;padding:20px 50px;cursor: pointer;"></i>
          </div>
        </div>

      </div>
         <div class="Dityo">
          <div class="dialog-footer">
            <!-- <el-button @click="Close_2">取 消</el-button> -->
            <el-button type="primary" @click="Close_5" style="margin-left:15px">保存设置</el-button>
          </div>
        </div> 

    </el-dialog>
    <!--选择部门负责人-->
  </div>
</template>

<script>
import axios from 'axios'
import  TextMoth  from "../../components/utils.vue";
import { fetchData } from "../../api/index";
let id = 1000;

export default {
  name: "basetable",
  data() {
    return {
      Row_piii:[],
      Row_piii2:[],
      DMent2w:[],
      Bmen_Sj1:'',
      Bmen_Sj2:'',
      Bmmm:false,
      Bumen_Xinj1:null,
      Bumen_Xinj2:null,
      fForm:{
        name:'',
        phone:'',
        staff_nums:'',
      }, 
      ziduan_id:0,
      Ad2_ziduan:'',
      FAFA_l:false,
      FAFA_2:false,
      RRealname:'',
      checkList:[],
      checkList2:[],
      ResginS:[],
      DData:[],
      ZMent:[],
      DMent:[],
      DMent2:[],
      tag_obj:[],
      Categ:[],
      Fond_tag:null,
      MMD:[],
      D_input:'', // 搜索部门
      C_input:'', // 搜索成员
      activeIndex: "1",
      more:'../../assets/img/more.png',
      show1:false,
      show11:false,
      show3:false,
      show33:false,
      input: "",
      num:'',

      Index1:true,   // 首页
      Index2:false,   // 部门管理
      Index4:false,
      Index5:false,    //离职员工
      
      query: {
          address: "",
          name: "",
          pageIndex: 1,
          pageSize: 10
      },


      multipleSelection_id: null,
      delList: [],

      dia_DEl:false,
      Remov_id:0,

      dialogVisible1: false,
      dialogVisible2: false,
      dialogVisible3: false,
      dialogVisible4: false,
      dialogVisible5: false,
      dialogVisible6: false,
      dialogVisible7: false,
      dialogVisible8: false,
      dialogVisible9: false,
      dialogVisible10: false,
      dialogVisible11: false,

      pageTotal: 0,
      form: {},
      idx: -1,
      id: -1,
      total:0,
      total1:0,
      total2:0,
      total3:0,
      value3:0,
      currentPage1:0,
      currentPage3:0,
      currentPage4:0,

      Dy:true,
      tot:0,  //分页
      editStaff_id:0,  //编辑是员工id
      options2:[{name:'待交接',id: 10},{name:'已交接',id: 20},{name:'不需交接',id: 30}],
      sex_arr:[{id:1,name:'男'},{id:2,name:'女'}],
      options1:[{id:10,name:'主动离职'}, {id:20,name:'被动��职'}],
      XX:'',
      diasible9:'',
      diasible9_id:0,

      upload:'https://rest-api.duxin365.com/admin/ajax/upload',
      heads:{token:''},
      
      department_id:0, //部门id

      aTY: 1,        //图片显示判断
          //图片地址
      full_image:'', //图片页面显示地址

      Row:{
        realname:'',     //姓名
        Eurl:'',         //头像
        mobile:'',       //手机号
        job:'',          //职务
        job_number:'',   //工号
        department_name:'',
        department_id:0,
        department_shangji:'',
        email:'',        //邮箱
        entry_time:'', //入职时间
        sex_text:'',     //性别
        identity_text:'', //员工角色
        work_year: '',    //工作年限
        F_ull_image:'',    //图片地址
        mobile_hide:0,
      },
      Chan_jieshouren:0, // 选择接收人下标
      Row2:{
        quit_time_text:'', //入职时间
        quit_time:'', //入职时间
        reason:'',    //离职原因
        type:"",      //离职类型:10=主动离职,20=被动离职
        type_text:'',
        quit_status:'',  //办理状态:10=待交接,20=已交接,30=不需交接
        quit_status_text:'',
        is_transfer:null,

        transfer: [
            {
              name:'订单资源',
              people:'转移所有档案',
              type: "order", //离职类型:10=主动离职,20=被动离职
              to_staff_id: 0, //接收人
              to_staff:{
                realname:'',    //接收人
              }
            },
            {
              name:'订单资源',
              people:'转移所有档案',
              type: "customer", //离职类型:10=主动离职,20=被动离职
              to_staff_id: 0, //接收人
              to_staff:{
                realname:'',    //接收人
              }
              
            },
            {
              name:'订单资源',
              people:'转移所有档案',
              type: "file", //离职类型:10=主动离职,20=被动离职
              to_staff_id: 0, //接收人
              to_staff:{
                realname:'',    //接收人
              }
            }
          ] //转移资源，is_transfer=1时显示
      },
      Row3: {        // 新增字段
        name: undefined, //字段名称
        title: undefined, //字段标题
        tip: "", //提示语
        type: "", //字段类型
        content: "", //选项内容
        is_require: 0, //是否必填项:1=是,0=否
        is_show: 0, //是否员工可见:1=是,0=否
        is_edit: 0, //是否可编辑:1=是,0=否
        status: "normal" //启用状态，hidden=未启用,normal=已启用
      },
      Type_List:['日期','日期时间','文件','图片','数字','列表','文本',],
      Type_List_text:'',
      R3_inou:[{name:'选项1',content:''},{name:'选项2',content:''}],
      staffInfo:{}, //存储员工信息
      
      DepartmentList:[],  //通讯录选择部门
      StaffList:[],       //通讯录选择员工
      Parents:[],         //通讯录层级
      Blo_Black:false,    //返回显示  

      Chan_Tonx:null,        //通讯录已选
      Chan_Tonx2:null,        //通讯录已选2
      Bumen_Tonx:null,        //部门已选
      Bumen_Tonx2:null,        //部门已选2

      selection_arr: null,     // 选中离职员工数组
      atat:0,
      atat2:0,
      StaFFList:{},
      DData22:[]
    };
  },
  mounted: function() {
    var that = this
        let token = window.localStorage.getItem("accessToken")
        that.heads.token = token
        axios({ 
          method: 'post',
          url: '/admin/company/staff/staffList',    // 员工列表
          data: {
            page: 1,
            offset:0,
            limit:10,
          },
        }).then(function (res) { 
            if (res.code == 401) {
              that.$router.push('/Login');
            }else {
              that.total1 = res.data.data.total
              that.DData = res.data.data.rows
              that.DData22 = res.data.data.rows

            }
        }) 

        axios({
          method: 'post',
          url: '/admin/auth/group/index',
        }).then(function (res) {
            if (res.code == 0) {
              that.$message.warning(res.data.msg);
            } else {
              that.DMent2w = res.data.data.rows
            }
        })

        axios.get('/admin/company/content/index?field_key=employee_type',{   // 人员类型
          }).then(function (res) {
            if (res.code == 0) {
                  that.$message.warning(res.data.msg);
            } else{ 
              that.MMD = res.data.data.enums
              that.Categ = res.data.data.enums 
            }
        })
        axios.get('/admin/company/department/index',{   // 部门列表
          }).then(function (res) {
            if (res.code == 0) {
              alert('请求失败');  
            } else{ 
              that.DMent = res.data.data.rows 
              that.DMent2 = res.data.data.rows
              that.department_id = res.data.data.rows[0].id
            }
        })
        axios.get('/admin/company/staff/quitList',{   // 模块
            params:{
              page: 1,
              offset:0,
              limit:10,
            }
          }).then(function (res) {
            if (res.code == 0) {
              alert('请求失败');  
            } else{ 
              that.total2 = res.data.data.total
              that.ResginS = res.data.data.rows 
            }
        })

        axios.get('/admin/company/staff/subListFilter',{   // 通讯录选择
            params:{
              id: 0
            }
          }).then(function (res) {
            if (res.code == 0) {
              alert('请求失败');  
            } else{ 
              that.DepartmentList = res.data.data.departmentList
              that.StaffList = res.data.data.staffList
              that.Parents = res.data.data.parents

            }
        })
        axios.get('/admin/company/staff/fieldsList',{   // 字段列表
            params:{
              page: 1,
              offset:0,
              limit:10,
            }
          }).then(function (res) {
            if (res.code == 0) {
              alert('请求失败');  
            } else{ 
              that.ZMent = res.data.data.rows 
              that.total3 = res.data.data.total 
            }
        })
  },
  methods: {
    TextMoMo(e) {
      var arr = TextMoth.getData(e)
      return arr  
    },
    handleChange(value) {
      var that = this
        console.log(value);
        console.log(that.Row_piii);
        
        var num = Number(that.Row_piii.slice(-1))
        console.log(num)
        that.Row.department_id = num
        console.log(that.Row.department_id);

    },
    handleChange2(value) {
      var that = this
        console.log(value);
        console.log(that.Row_piii2);
        var arr = []
        var num = Number(that.Row_piii2.slice(-1))
        console.log(num)
        arr.push(num)
        that.Row.group = arr
        console.log(that.Row.department_id);

    },
    Staff_Show1(e,data) {  // 点击编辑员工
      console.log(data)
      var that = this
      that.editStaff_id = data.id
      that.XX = 'bj'
      if (e == 1) {
          that.show11 = false
          that.show1 = true
          
          that.aTY = 1
      } else if (e == 2){
          that.show1 = false
          that.show11 = true
      }
        axios.get('/admin/company/staff/staffInfo',{
          params:{
              id: data.id
          }
        }).then(function (res) {
          that.staffInfo = res.data.data.row
          var Dates = res.data.data.row
          console.log(Dates)
          that.Row = Dates
          that.Fond_tag = Dates.type_id
          that.tag_obj = Dates.type_id
          that.Row_piii = Dates.department_id                                       
          that.Row.department_id = Dates.department.id
          // that.Row.department_shangji = that.Row.leader.id
          that.Row.leader_id = parseInt(Dates.leader_id)
          that.atat2 = that.Row.department_id
          that.Row.Eurl = that.Row.full_avatar

          console.log(that.Row)
          var cccc = Number(Dates.group.slice(-1))
          that.Row_piii2 = cccc
          if (Dates.sex == '1') {
            that.Row.sex_text = '男'
           
          } else{
            that.Row.sex_text = '女'
          }
        })

    },
    Staff_Add(e) {   // 完成添加员工
      var that = this
      var Sex = 0
      var date = new Date(that.Row.entry_time)
      var y = date.getFullYear()
      var m = date.getMonth() + 1
      m = m < 10 ? ('0' + m) : m
      var d = date.getDate()
      d = d < 10 ? ('0' + d) : d
      const time = y + '-' + m + '-' + d
      // return time
      console.log(that.Row.department_id)

      if (that.Row.sex_text == '男') {
        Sex = 1
      } else {
        Sex = 2
      }
      that.isPhone(that.Row.mobile)
      if (that.FAFA_l == true) {
        that.isEmail(that.Row.email)
        if (that.FAFA_2 == true) {
          if (e == 1) {   // 新建员工确认
            axios({
              method: 'post',
              url: '/admin/company/staff/addStaff',
              data: {
                row: {
                  department_id: that.Row.department_id, //部门ID
                  realname: that.Row.realname, //员工姓名
                  avatar: that.Row.Eurl, //头像
                  mobile: that.Row.mobile, //手机号
                  email: that.Row.email, //邮箱
                  sex: Sex, //性别:0=未知,1=男,2=女
                  job: that.Row.job, //职位
                  job_number: that.Row.job_number, //工号
                  work_year: "5", //工作年限
                  entry_time: time, //入职时间
                  mobile_hide: parseInt(that.Row.mobile_hide), //是否隐藏手机号:1=隐藏,0=不隐藏
                  leader_id: that.atat, //直属上级ID
                  type_id: that.tag_obj, //人员类型
                  group: that.Row.group
                }
              },
            }).then(function (res) {
                if (res.data.code == 0) {
                  that.$message.warning(res.data.msg);
                } else {
                    that.$message.success(res.data.msg);
                    that.show1 = false
                    axios({ 
                      method: 'post',
                      url: '/admin/company/staff/staffList',    // 员工列表
                      data: {page: 1,offset:0,limit:10,},
                    }).then(function (res) {
                        if (res.code == 0) {
                          that.$message.warning(res.data.msg);
                        } else {
                          that.total1 = res.data.data.total
                          var arr = res.data.data.rows
                          that.DData = res.data.data.rows
                        }
                    }) 
                }
            }) 
          } else if(e == 2) {  // 编辑员工确认
            axios({
              method: 'put',
              url: '/admin/company/staff/editStaff',
              data: {
                id: that.editStaff_id,
                row: {
                  department_id: that.Row.department_id, //部门ID
                  realname: that.Row.realname, //员工姓名
                  avatar: that.Row.Eurl, //头像
                  mobile: that.Row.mobile, //手机号
                  email: that.Row.email, //邮箱
                  sex: Sex, //性别:0=未知,1=男,2=女
                  job: that.Row.job, //职位
                  job_number: that.Row.job_number, //工号
                  work_year: "5", //工作年限
                  entry_time: time, //入职时间
                  mobile_hide: parseInt(that.Row.mobile_hide), //是否隐藏手机号:1=隐藏,0=不隐藏
                  leader_id: that.Row.leader_id, //直属上级ID
                  type_id: that.tag_obj, //人员类型
                  group: that.Row.group
                }
              },
            }).then(function (res) {
                if (res.data.code == 0) {
                  that.$message.warning(res.data.msg);
                } else {
                    that.$message.success(res.data.msg);
                    that.show1 = false
                    axios({ 
                      method: 'post', url: '/admin/company/staff/staffList',    // 员工列表
                      data: {page: 1,offset:0,limit:10,},
                    }).then(function (res) {
                        if (res.code == 0) {
                          that.$message.warning(res.data.msg);
                        } else {
                          var arr = res.data.data.rows
                          that.DData = res.data.data.rows
                        }
                    }) 
                }
            }) 
          }

        }
      } 
      

      


    },
    Close(e) {
      this.dialogVisible3 = false;
      this.dialogVisible4 = false;
      this.dialogVisible5 = false;
      this.dialogVisible6 = false;
      this.dialogVisible7 = false;
      this.dialogVisible8 = false;
      this.dialogVisible10 = false;
      this.dialogVisible11 = false;
      this.show1 = false;
      this.show11 = false;
      this.show3 = false;
      this.show33 = false;
    },
    handleEdit2(e) {
      console.log(e.row.inpu)
    },
    Del_Sure() {  // 删除确定
      var that = this
      if (that.del_idw == 'Ment') {
        axios({
            method: 'delete',
            url: '/admin/company/department/del',
            data: {
              id: that.Remov_id
            },
          }).then(function (res) {

              if (res.data.code == 0) {
                that.$message.warning(res.data.msg);
              } else {
                that.dia_DEl = false  
                that.$message.success(res.data.msg);
                axios.get('/admin/company/department/index',{   // 模块
                  }).then(function (res) {
                    if (res.code == 0) {
                      alert('请求失败');  
                    } else{ 
                      that.DMent = res.data.data.rows
                    }
                })
              }
          }) 
      }
    },

    More(data) {
      console.log('123')
      console.log(data)
    },
    UP_Success(e) {  //图片上传成功
      var that = this
      console.log(e)
      that.Row.Eurl = e.data.url
      that.aTY = 0

    },
    Huoqu(e) {
      var that = this
      that.dialogVisible7 = true
    },
    Huoqu2(e) {
      var that = this
      that.dialogVisible8 = true
      that.Chan_Tonx2 = null
    },
    BTN_Bumen3() {
      var that = this
      that.show1 = false
      that.dialogVisible7 = false
      that.Row.department_name = that.Bumen_Tonx2.name
      that.atat2 = that.Bumen_Tonx2.id
      setTimeout(function(){
        that.show1 = true
      },1)
    },
    Chang_ses2(data) {  //人员类型标签选择
      var that = this
      var att = that.MMD
      var num = null
      console.log(data);

      var arr = ''
      arr = that.Fond_tag.toString()

      console.log(that.Fond_tag)
      console.log(arr)


      that.tag_obj = arr
      console.log(that.tag_obj)
    },
    Add_leibie() {  //创建导航类别
      var that = this
      var ATT = that.Categ
      var srt = {
        content: "新加",
        status: 'normal',
        type: "custom",
        value:0,
      }
      ATT.push(srt)
      that.Categ = ATT
    },
    changeSwitch(data,b,index) {   // radio
      var that = this
      var Categ = that.Categ
      console.log(Categ)
      console.log(index)
      console.log('当前值' + Categ[index].status)
      if (Categ[index].status == 'normal') {
        Categ[index].status = 'hidden'
        console.log('进1改变为0')
      } else if (Categ[index].status == 'hidden') {
        console.log('进0改变为1')
        Categ[index].status = 'normal'
      }
      that.Categ = Categ
    },
    handleDelete(index, row) {  // 删除导航类别
      var that = this
      console.log(row)
      var ATT = that.Categ
      if (row.type == 'default') {

      } else {
        for (let i = 0; i < ATT.length; i++) {
          const ele = ATT[i];
          if (ele.id == row.id) {
            ATT.splice(index,1);
          }
        }
        that.Categ = ATT
      }

    },
    NabCH_BTN() {
      var that = this
      var ATT = that.Categ
        axios({
          method: 'post',
          url: '/admin/company/content/edit',
          data: {
            field_key: "employee_type", //内容标识
            content: ATT
          },
        }).then(function (res) {
            if (res.code == 0) {
              that.$message.warning(res.data.msg);
            } else {
              that.dialogVisible1 = false
                axios.get('/admin/company/content/index?field_key=employee_type',{   // 模块
                  }).then(function (res) {
                    if (res.code == 0) {
                      that.$message.warning(res.data.msg);
                    } else{ 
                      that.MMD = res.data.data.enums
                      that.Categ = res.data.data.enums
                      console.log(res.data.data.enums)
                    }
                })

            }

        }) 
    },
    handleClick2(data,checked, node){  // 勾选变更功能部门内
      var that = this
      that.Bumen_Tonx2 = data
      console.log(data)
    },
    bindCheckBox(value){     //通讯录选择员工
      console.log(value)
      var that = this
      
        if(that.checkList.length > 1){
            that.checkList.splice(0,1)
        }
        var arr = that.checkList.toString()
        console.log(arr)

        var brr = that.StaffList
        console.log(brr)
        for (let i = 0; i < brr.length; i++) {
          if (brr[i].id == arr) {
            that.Chan_Tonx = brr[i]
            that.Chan_Tonx2 = brr[i]
          }
        }
        console.log(that.Chan_Tonx)
        if (value == '') {
          that.Chan_Tonx = null
          that.Chan_Tonx2 = null
        }

    },
    Nice_x(e) {             //通讯录下级
        var that = this
        that.Blo_Black = true
        axios.get('/admin/company/staff/subListFilter',{
            params:{
              id: e
            }
          }).then(function (res) {
            if (res.code == 0) {
              alert('请求失败');  
            } else{ 
              that.DepartmentList = res.data.data.departmentList
              that.StaffList = res.data.data.staffList
              that.Parents = res.data.data.parents
              console.log(res.data.data)
            }
        })
    },
    Close_2() {             //通讯录取消选择
      var that = this
      console.log('123')
      console.log(that.dialogVisible5)
      that.Chan_Tonx = null
      that.dialogVisible5 = false;

    },
    Close_3() {
      var that = this
      var arr = that.Row2.transfer

      that.Row2.transfer[that.Chan_jieshouren].to_staff.realname = that.Chan_Tonx.realname
      that.Row2.transfer[that.Chan_jieshouren].to_staff_id = that.Chan_Tonx.id
      that.dialogVisible5 = false;
      that.show3 = false
      console.log(that.dialogVisible5)
      setTimeout(function(){
        that.show3 = true
      },1)    
    },
    Close_4() {
      var that = this
      that.Row.department_shangji = that.Chan_Tonx2.realname
      that.atat = that.Chan_Tonx2.id
      that.dialogVisible8 = false;
      that.show1 = false
      console.log(that.Chan_Tonx2)
      setTimeout(function(){
        that.show1 = true
      },1)    
    },
    Bloo_Black(e) {         // 通讯录选择
          var that = this
          axios.get('/admin/company/staff/subListFilter',{   
            params:{
              id: e
            }
          }).then(function (res) {
            if (res.code == 0) {
              alert('请求失败');  
            } else{ 
              that.DepartmentList = res.data.data.departmentList
              that.StaffList = res.data.data.staffList
              that.Parents = res.data.data.parents
              console.log(res)
            }
        })
    },

 
    handlePictureCardPreview(file) { 
      console.log(file)
      var that = this
      that.full_image = file.url;
    },
    Add_kq(e) {
      var that = this
      if (e == 1) {
        that.show1 = true
        that.XX = 'xin'
        that.Fond_tag = null
        that.Row = {}
        that.Row.mobile_hide = 0
        // that.Row.department.name = '222'
      } else if(e == 2){
        that.Row3 = {}
        that.Type_List_text = ''
        that.R3_inou = [{name:'选项1',content:''},{name:'选项2',content:''}]
        that.dialogVisible2 = true;
        that.Ad2_ziduan = 'xin'
      } else if (e == 3) {
        that.show1 = false
        that.show3 = true
      }
    },
    Staff_del(e,data) {   // 删除员工
    console.log(e)
    console.log(data.id)
      var that = this
        that.$confirm("确定要删除吗？", "提示", {
            type: "warning"
        }).then(() => {
          axios.delete('/admin/company/staff/delStaff',{   // 模块
            params:{
                  id: data.id
            }
          }).then(function (res) {
          if (res.code == 0) {
                that.$message.warning(res.data.msg);
          } else{ 
              that.$message.success(res.data.msg);
                axios({ 
                  method: 'post',
                  url: '/admin/company/staff/staffList',    // 员工列表
                  data: {
                    page: 1,
                    offset:0,
                    limit:10,
                  },
                }).then(function (res) {
                    if (res.code == 0) {
                      that.$message.warning(res.data.msg);
                    } else {
                      that.total1 = res.data.data.total
                      var arr = res.data.data.rows
                      that.DData = res.data.data.rows
                    }

                }) 
          }
        })

        })
            .catch(() => {});
    },

    handleSelect(key, keyPath) {  // 上访切换
      var that = this
      console.log(key, keyPath);
      if (key == 4) {
        this.$router.push('/Manage/BaseTable4');
      } else if (key == 1) {
        that.Index1 = true
        that.Index2 = false
        that.Index4 = false
        that.Index5 = false
      } else if(key == 2) {
        this.$router.push('/Manage/BaseTable2');
      }
    },




    Selection(e) {    // 勾选成员
      var that = this
      var arr = []
      if (e.length == 0) {
        that.multipleSelection_id = null
      } else {
        for (let i = 0; i < e.length; i++) {
          const element = e[i];
          arr.push(element.id)
        }
              that.multipleSelection_id = arr
      }
    },
    handleCurrentChange1(val) {    // 员工分页 ···············································
      var that = this
      that.tot = val
      console.log(val)
      that.currentPage1 = val
        axios({ 
          method: 'post',
          url: '/admin/company/staff/staffList',    // 员工列表

            params:{
              page: val,
              limit: 10
            }

        }).then(function (res) {
            if (res.code == 0) {
              that.$message.warning(res.data.msg);
            } else {
              that.total1 = res.data.data.total
              var arr = res.data.data.rows
              that.DData = res.data.data.rows
            }

        }) 

    },
    C_sech() {    // 搜索人员
        var that = this
        axios({ 
          method: 'post',
          url: '/admin/company/staff/staffList',    // 员工列表
          data: {
            page: 1,
            offset:0,
            limit:10,
            filter:{
              keyword:that.C_input
            }
          },
        }).then(function (res) {
            if (res.code == 0) {
              that.$message.warning(res.data.msg);
            } else {
              that.total1 = res.data.data.total
              var arr = res.data.data.rows
              that.DData = res.data.data.rows
            }

        }) 
    },



    //SSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSS ------------------------------------------部门管理---------------SSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSsS-------------------------------------------
    Bmen1(e) {
      var that = this
      if(e == 1) {
        that.dialogVisible10 = true;
      }else if(e == 2) {
        that.dialogVisible11 = true;
      }
    },
    Bmen1_Chan(data) {
      var that = this
      that.Bumen_Xinj1 = data
      console.log(data)
    },
    BTN_Bumen2() {   //选择上级部门确认
      var that = this
      that.dialogVisible10 = false;
      that.Bmen_Sj1 = that.Bumen_Xinj1.name
      console.log(that.Bumen_Xinj1)
      console.log(that.Bmen_Sj1)
      setTimeout(function(){
        that.dialogVisible10 = false;
        that.dialogVisible11 = false;
      },1)    
    },
    quxiao() {
      var that = this
        that.dialogVisible10 = false;
        that.dialogVisible11 = false;
        that.Bumen_Xinj1 = null
        that.Bumen_Xinj2 = null
    },
    bindCheckBox2(value){     //  勾选部门负责人
      console.log(value)
      var that = this
      
        if(that.checkList2.length > 1){
            that.checkList2.splice(0,1)
        }
        var arr = that.checkList2.toString()
        console.log(arr)

        var brr = that.StaffList
        console.log(brr)
        for (let i = 0; i < brr.length; i++) {
          if (brr[i].id == arr) {
            that.Bumen_Xinj2 = brr[i]
          }
        }
        console.log(that.Bumen_Xinj2)
        if (value == '') {
          that.Bumen_Xinj2 = null
        }
    },
    Close_5() {
      var that = this
      that.Bmen_Sj2 = that.Bumen_Xinj2.realname
      that.dialogVisible10= false;
      that.dialogVisible11 = false;
      setTimeout(function(){
        that.dialogVisible11 = false
        that.dialogVisible10 = false
      },1)    
    },

    // EEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEE------------------------------------------部门管理-------------EEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEE----------------------------------------



    //SSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSS ------------------------------------------人员管理左侧---------------SSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSsS-------------------------------------------
    D_sech() {                //搜索部门
        var that = this
        axios.get('/admin/company/department/index',{
            params:{
                keyword: that.D_input
            }
          }).then(function (res) {
            if (res.code == 0) {
              alert('请求失败');  
            } else{ 
              that.DMent = res.data.data.rows
            }
        })
    },
    handleNodeClick(data) {  // 点击部门 人员切换
      var that = this
        axios({ 
          method: 'post',
          url: '/admin/company/staff/staffList',    // 员工列表
          data: {
            page: 1,
            offset:0,
            limit:10,
            filter:{
              department_id:data.id
            }
          },
        }).then(function (res) {
            if (res.code == 0) {
              that.$message.warning(res.data.msg);
            } else {
              that.total1 = res.data.data.total
              var arr = res.data.data.rows
              that.DData = res.data.data.rows
            }

        }) 
    },
    remove(node, data) {  // 部门删除
      var that = this
      that.dia_DEl = true
      that.del_idw = 'Ment'
      that.Remov_id = data.id
    },
    bianji(e) {   //编辑
      console.log(e)
      var that = this
      that.dialogVisible9 = true
      that.diasible9 = e.name
      that.diasible9_id = e.id
      
    },
    Btn_xiugai() {   //该部门名字确认
        var that = this
        axios({
          method: 'put',
          url: '/admin/company/department/edit',
          data: {
            id: that.diasible9_id,
            row:{
              name: that.diasible9
            }
          },
        }).then(function (res) {
            if (res.data.code == 0) {
              that.$message.warning(res.data.msg);
            } else {
                that.dialogVisible9 = false
              axios.get('/admin/company/department/index',{   // 部门列表
                }).then(function (res) {
                  if (res.code == 0) {
                    alert('请求失败');  
                  } else{ 
                    that.DMent = res.data.data.rows
                    that.DMent2 = res.data.data.rows
                    that.department_id = res.data.data.rows[0].id
                  }
              })
            }
        }) 
    },
    append(data) {   //添加子部门
    console.log(data)
    var that = this
      const newChild = { id: 0, name: '新加子部门', children: [] }
      if (!data.children) {
        data.children = []
      }
      data.children.push(newChild)
      that.DMent = [...that.DMent]
      axios({ 
          method: 'post',
          url: '/admin/company/department/add',    // 新建部门
          data: {
            row:{
              pid: data.id, //上级部门ID
              name: '新加子部门',
              leader_id: "2", //主管ID
            }
          },
        }).then(function (res) {
            if (res.code == 0) {
              that.$message.warning(res.data.msg);
            } else {
              axios.get('/admin/company/department/index',{   // 部门列表
                }).then(function (res) {
                  if (res.code == 0) {
                    alert('请求失败');  
                  } else{ 
                    that.DMent = res.data.data.rows
                    that.DMent2 = res.data.data.rows
                    that.department_id = res.data.data.rows[0].id
                  }
              })
            }

        }) 
    },
    // EEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEE------------------------------------------人员管理左侧-------------EEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEE----------------------------------------



    //SSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSS ------------------------------------------离职员工---------------SSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSsS-------------------------------------------
    Resgin() {              // 进入已离职员工页面
        var that = this
        that.Index1 = false
        that.Index5 = true

        axios.get('/admin/company/staff/quitList',{
            params:{
              page: 1,
              offset:0,
              limit:10,
            }
          }).then(function (res) {
            if (res.code == 0) {
              alert('请求失败');  
            } else{ 
              that.total2 = res.data.data.total
              that.ResginS = res.data.data.rows
              console.log(that.ResginS)
            }
        })

    },
    Black() {
        var that = this
        that.Index1 = true
        that.Index5 = false
    },
    Staff_del2(e,data) {   // 删除离职员工
      var that = this
        var arr = []
        arr.push(data.id)
        that.$confirm("确定要删除吗？", "提示", {
            type: "warning"
        }).then(() => {
          axios.delete('/admin/company/staff/delQuit',{   // 模块
            params:{
                  ids: arr
            }
          }).then(function (res) {
          if (res.code == 0) {
                that.$message.warning(res.data.msg);
          } else{ 
              that.$message.success(res.data.msg);
              axios.get('/admin/company/staff/quitList',{   // 模块
                  params:{
                    page: 1,
                    offset:0,
                    limit:10,
                  }
                }).then(function (res) {
                  if (res.code == 0) {
                    alert('请求失败');  
                  } else{ 
                    that.total2 = res.data.data.total
                    that.ResginS = res.data.data.rows
                    console.log(that.ResginS)
                  }
              })
          }
        })

        })
            .catch(() => {});
    },
    Staff_del3(e,data) {   // 批量删除离职员工
      var that = this
      if (that.selection_arr == null) {
        that.$message.warning('请勾选员工');
      } else {
        that.$confirm("确定要删除吗？", "提示", {
            type: "warning"
        }).then(() => {
          axios.delete('/admin/company/staff/delQuit',{   // 模块
            params:{
                  ids: that.selection_arr
            }
          }).then(function (res) {
          if (res.code == 0) {
                that.$message.warning(res.data.msg);
          } else{ 
              that.$message.success(res.data.msg);
              axios.get('/admin/company/staff/quitList',{   // 模块
                  params:{
                    page: 1,
                    offset:0,
                    limit:10,
                  }
                }).then(function (res) {
                  if (res.code == 0) {
                    alert('请求失败');  
                  } else{ 
                    that.total2 = res.data.data.total
                    that.ResginS = res.data.data.rows
                    console.log(that.ResginS)
                  }
              })
          }
        })

        })        
      }

    },
    select_change(sel) {   // 离职员工勾选
      var that = this
      console.log(sel)
      var searr = []
      for (let i = 0; i < sel.length; i++) {
        const ele = sel[i].id;
        searr.push(ele)
      }
      console.log(searr)
      // let aee = Array.from(searr)
      that.selection_arr = searr
      console.log(that.selection_arr)
    },
    Edit_lizhi(e, data) {      //点击编辑  离职
    var that =this
      
      console.log(data)
      if (e == 2) {
        that.show3 = false
        that.show33 = true

      } else if(e == 1){
        that.show3 = false
        that.show33 = true
      }
        axios({
          method: 'put',
          url: '/admin/company/staff/quitInfo',
          data: {
            id: data.id,
          },
        }).then(function (res) {
            if (res.data.code == 0) {
              that.$message.warning(res.data.msg);
            } else {
              that.staffInfo = res.data.data.row.staff
              that.Row2 = res.data.data.row
              console.log(that.Row2)
            }
        })       
    },
    Select_quit(e) {        //办理离职 办理状态
      var that = this
      if(e == '待交接') {
        that.Row2.quit_status = 10
      } else if(e == '待交接') {
        that.Row2.quit_status = 20
      } else {
          that.Row2.quit_status = 30
      }
    },
    Select_typ(e) {         //办理离职 离职类型
      var that = this
      if(e == '主动离职') {
        that.Row2.type = 10
      } else if(e == '被动离职') {
        that.Row2.type = 20
      } 
    },
    Chang_ipu(e) {          // 办理离职获取焦点选择人员
      this.dialogVisible5 = true
      console.log(e)
      this.Chan_jieshouren = e
    },
    BTN_lizhi() {           // 离职保存设置
      var that =this
      var add = that.formatDate(that.Row2.quit_time_text)

      console.log(add)
      console.log(that.Row2.quit_status_text)
        axios({ 
          method: 'post',
          url: '/admin/company/staff/addQuit',   
          data: {
            row: {
                staff_id: that.editStaff_id, //离职员工ID
                reason: that.Row2.reason, //离职原因
                type: that.Row2.type, //离职类型:10=主动离职,20=被动离职
                quit_status: that.Row2.quit_status, //办理状态:10=待交接,20=已交接,30=不需交接
                quit_time: add, //离职时间
                is_transfer: that.Row2.is_transfer, //操作选择:0=直接删除,1=转移后删除
                transfer: that.Row2.transfer
              }
          },
          
        }).then(function (res) {
            if (res.code == 0) {
              
              that.$message.warning(res.data.msg);
            } else {
              that.$message.success(res.data.msg);
              that.show3 = false
            }

        })
    },
    // EEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEE------------------------------------------离职员工-------------EEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEE----------------------------------------



    //SSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSS ------------------------------------------变更部门---------------SSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSsS-------------------------------------------
    handleClick(data,checked, node){  // 勾选变更功能部门内
      var that = this
      that.Bumen_Tonx = data
      console.log(data)
    },
    Close_Bumen() {  // 删除已选部门
      var that = this
      that.Bumen_Tonx = null
      that.Bumen_Tonx2  = null
    },
    BTN_Bumen() {    //确认变更部门
        var that = this
        axios({ 
          method: 'post',
          url: '/admin/company/staff/transfer',    // 员工列表
          data: {
            ids: that.multipleSelection_id,
            department_id:that.Bumen_Tonx.id
          },
        }).then(function (res) {
            if (res.code == 0) {
              that.$message.warning(res.data.msg);
            } else {
              that.$message.success(res.data.msg);
              that.dialogVisible6 = false
                      axios({ 
          method: 'post',
          url: '/admin/company/staff/staffList',    // 员工列表
          data: {
            page: 1,
            offset:0,
            limit:10,
          },
        }).then(function (res) {
            if (res.code == 0) {
              that.$message.warning(res.data.msg);
            } else {
              that.total1 = res.data.data.total
              var arr = res.data.data.rows
              that.DData = res.data.data.rows
            }

        }) 
            }

        }) 
    },
    Bian_bumen() {    // 点击变更部门
      var that = this

      console.log(that.multipleSelection_id)
      if (that.multipleSelection_id == null) {
        that.$message.warning('请选择人员');
      } else if(that.multipleSelection_id.length == 0) {
        that.$message.warning('请选择人员');
      } else{
        that.dialogVisible6 = true

        axios.get('/admin/company/department/index',{   // 部门列表
          }).then(function (res) {
            if (res.code == 0) {
              alert('请求失败');  
            } else{ 
              that.DMent2 = res.data.data.rows
              that.department_id = res.data.data.rows[0].id
                  that.Bumen_Tonx = null 
            }
        })
        that.DMent = that.DMent2
      }

    },
    // EEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEE------------------------------------------变更部门-------------EEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEE----------------------------------------
    
    


//SSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSS----------------------------------------------新增字段------------------------------SSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSS-----------------------
    Del_xinz(e) {    // 删除字段
    var that = this
          that.$confirm("确定要删除吗？", "提示", {
            type: "warning"
        }).then(() => {
          axios.delete('/admin/company/staff/delFields',{   // 模块
            params:{
                  id: e.id
            }
          }).then(function (res) {
          if (res.code == 0) {
                that.$message.warning(res.data.msg);
          } else{ 
              that.$message.success(res.data.msg);
              axios.get('/admin/company/staff/fieldsList',{   // 字段列表
                  params:{
                    page: 1,
                    offset:0,
                    limit:10,
                  }
                }).then(function (res) {
                  if (res.code == 0) {
                    alert('请求失败');  
                  } else{ 
                    that.ZMent = res.data.data.rows
                    // that.Type_List = res.data.data.typeList
                    that.total3 = res.data.data.total
                  }
              })
          }
        })
        })
    },  
    Din_xinz(e) {      // 停用  //启用
    console.log(e)
     var that = this
     if (e.status == 'normal') {
        e.status = 'hidden'
        console.log(e)
            axios({
              method: 'put',
              url: '/admin/company/staff/editFields',
              data: {
                id: e.id,
                row: e
              },
            }).then(function (res) {
                if (res.data.code == 0) {
                  that.$message.warning(res.data.msg);
                } else {

                }
            })      
     } else if(e.status == 'hidden'){
        e.status = 'normal'
                    axios({
              method: 'put',
              url: '/admin/company/staff/editFields',
              data: {
                id: e.id,
                row: e
              },
            }).then(function (res) {
                if (res.data.code == 0) {
                  that.$message.warning(res.data.msg);
                } else {

                }
            })  
     }
      that.Dy = false
    },
    Edit_xinz(e) {   //编辑
      var that = this
      that.Ad2_ziduan = ''
      console.log(e)
      that.dialogVisible2 = true
      that.ziduan_id = e.id
            
        axios.get('/admin/company/staff/fieldsInfo',{   // 模块
          params:{
              id:e.id
          }
          }).then(function (res) {
            if (res.code == 0) {
                that.$message.warning(res.data.msg);
            } else{ 
              that.Row3 = res.data.data.row
              that.R3_inou = res.data.data.row.content
              var arr = res.data.data.row.type
              console.log(that.Row3)
              if (arr == 'date') {
                that.Type_List_text = '日期'
              }else if(arr == 'datetime') {
                that.Type_List_text = '日期时间'
              } else if(arr == 'datetime') {
                that.Type_List_text = '文件'
              } else if(arr == 'image') {
                that.Type_List_text = '图片'
              } else if(arr == 'number') {
                that.Type_List_text = '数字'
              } else if(arr == 'select') {
                that.Type_List_text = '列表'
              } else if(arr == 'string') {
                that.Type_List_text = '文本'
              } else if(arr == 'text') {
                that.Type_List_text = '文本'
              }
            }
        })
    },
    Add_cent() {    //新增选项内容
      var that = this
      var arr = that.R3_inou
      var num = 0
      for (let i = 0; i < arr.length; i++) {
        const element = arr[i];
        num = i + 2
      }
      console.log(num)
      var att = {
        name:'选项' + num,
        content:''
      }
      arr.push(att)
      that.R3_inou = arr

    },
    BTN_ziduan(e) {    //新增编辑字段确认
        var that = this
            console.log(that.Row3)
        if (that.Row3.name == undefined || that.Row3.name == '') {
          that.$message.warning('字段名称不能为空');
        } else if(that.Row3.title == undefined || that.Row3.title == '') {
          that.$message.warning('字段标题不能为空');
        } else{
          if (e == 1) {
            axios({ 
              method: 'put',
              url: '/admin/company/staff/editFields',    
              data: {
                id:that.ziduan_id,
                row:{
                  name: that.Row3.name, //字段名称
                  title: that.Row3.title, //字段标题
                  tip: that.Row3.tip, //提示语
                  type: that.Row3.type, //字段类型
                  content: that.R3_inou, //选项内容
                  is_require: that.Row3.is_require, //是否必填项:1=是,0=否
                  is_show: that.Row3.is_show, //是否员工可见:1=是,0=否
                  is_edit: that.Row3.is_edit, //是否可编辑:1=是,0=否
                  status: "normal" //启用状态，hidden=未启用,normal=已启用
                }
              },
              
            }).then(function (res) {
                if (res.code == 0) {
                  
                  that.$message.warning(res.data.msg);
                } else {
                  that.dialogVisible2 = false
                  // that.$message.success(res.data.msg);
                  axios.get('/admin/company/staff/fieldsList',{   // 字段列表
                      params:{
                        page: 1,
                        offset:0,
                        limit:10,
                      }
                    }).then(function (res) {
                      if (res.code == 0) {
                        alert('请求失败');  
                      } else{ 
                        that.ZMent = res.data.data.rows
                        that.total3 = res.data.data.total
                      }
                  })
                }

            }) 
          } else {
            axios({ 
              method: 'post',
              url: '/admin/company/staff/addFields',    
              data: {
                
                row:{
                  name: that.Row3.name, //字段名称
                  title: that.Row3.title, //字段标题
                  tip: that.Row3.tip, //提示语
                  type: that.Row3.type, //字段类型
                  content: that.R3_inou, //选项内容
                  is_require: that.Row3.is_require, //是否必填项:1=是,0=否
                  is_show: that.Row3.is_show, //是否员工可见:1=是,0=否
                  is_edit: that.Row3.is_edit, //是否可编辑:1=是,0=否
                  status: "normal" //启用状态，hidden=未启用,normal=已启用
                }
              },
              
            }).then(function (res) {
                if (res.code == 0) {
                  
                  that.$message.warning(res.data.msg);
                } else {
                  that.dialogVisible2 = false

                  axios.get('/admin/company/staff/fieldsList',{   // 字段列表
                      params:{
                        page: 1,
                        offset:0,
                        limit:10,
                      }
                    }).then(function (res) {
                      if (res.code == 0) {
                        alert('请求失败');  
                      } else{ 
                        that.ZMent = res.data.data.rows
                        that.total3 = res.data.data.total
                      }
                  })
                }

            }) 
          }

      }

    },
    Type_Chan(e) {    //字段类型改变
      console.log(e)
      var that = this
      if (e == '日期') {
        that.Row3.type = 'date'
      } else if (e == '日期时间') {
        that.Row3.type = 'datetime'
      } else if (e == '文件') {
        that.Row3.type = 'file'
      } else if (e == '图片') {
        that.Row3.type = 'image'
      } else if (e == '数字') {
        that.Row3.type = 'number'
      } else if (e == '列表') {
        that.Row3.type = 'select'
      } else if (e == '文本' || e == 'text') {
        that.Row3.type = 'string'
      } 
    },
    Switch1(e) {
      console.log(e)
      var that = this
            axios({
              method: 'put',
              url: '/admin/company/staff/editFields',
              data: {
                id: e.id,
                row: e
              },
            }).then(function (res) {
                if (res.data.code == 0) {
                  that.$message.warning(res.data.msg);
                } else {

                }
            })
    },
    Switch2(e) {
      console.log(e)
      var that = this
            axios({
              method: 'put',
              url: '/admin/company/staff/editFields',
              data: {
                id: e.id,
                row: e
              },
            }).then(function (res) {
                if (res.data.code == 0) {
                  that.$message.warning(res.data.msg);
                } else {

                }
            })
    },
    Switch3(e) {
      console.log(e)
      var that = this
            axios({
              method: 'put',
              url: '/admin/company/staff/editFields',
              data: {
                id: e.id,
                row: e
              },
            }).then(function (res) {
                if (res.data.code == 0) {
                  that.$message.warning(res.data.msg);
                } else {

                }
            })
    },
    handleCurrentChange3(val) {    // 分页 ···············································
      var that = this
      that.tot = val
      console.log(val)
      that.currentPage3 = val
      console.log(that.currentPage3)
        axios.get('/admin/company/staff/fieldsList',{   // 字段列表
          params:{
              page: val,
              limit: 8
          } 
          }).then(function (res) {
            if (res.code == 0) {
                that.$message.warning(res.data.msg);
            } else{ 
              that.total3 = res.data.data.total
              that.ZMent = res.data.data.rows
              console.log(that.ZMent)
            }
        })


    },
//EEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEE--------------------------------------------新增字段---------------------EEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEE------------------------------------


    // 编辑操作
    handleEdit(index, row) {
        this.idx = index;
        this.form = row;
        this.editVisible = true;
    },
    formatDate(value) {    // 时间戳
        let date = new Date(value);
        let y = date.getFullYear();
        let MM = date.getMonth() + 1;
        MM = MM < 10 ? "0" + MM : MM;
        let d = date.getDate();
        d = d < 10 ? "0" + d : d;

        return y + "-" + MM + "-" + d
    },
    isPhone(value) {
      var that = this
      console.log(value)
      const reg = /^[1][3,4,5,7,8][0-9]{9}$/;
      if (value == '' || value == undefined || value == null) {
        that.callback();
      } else if((!reg.test(value)) && value != ''){
          that.callback();
      } else {
        that.FAFA_l = true
      }
    },
    isEmail(value) {
      var that = this
      const reg = /^([a-zA-Z0-9]+[-_\.]?)+@[a-zA-Z0-9]+\.[a-z]+$/;
      if (value == '' || value == undefined || value == null) {
        that.callback2();
      } else  if (!reg.test(value)){
          that.callback2();
      } else {
        that.FAFA_2 = true
      }
    },
    callback() {
      var that = this
      that.$message.warning('请输入正确的电话号码或邮箱');
      that.FAFA_l = false
    },
    callback2() {
      var that = this
      that.$message.warning('请输入正确的电话号码或邮箱');
      that.FAFA_2 = false
    }
  },

};
</script>


<style scoped> 
.BaseTable_I .Tabless {
  width: 72.2%;
  float: right;
  background: #fff;
  margin: 10px 20px 30px 0;
  padding: 24px;
  padding-bottom: 40px;
  border-radius: 4px;
} 
.BaseTable_I .Tables2 {
  box-sizing: border-box; 
  height: auto;
  background: #fff;
  margin:10px 24px 0;
  padding: 24px;
  overflow: hidden;
  overflow-y: auto;
  min-height: calc(100% - 180px);
  height: calc(100% - 450px);
  padding-bottom: 90px;
}
.BaseTable_I .Tables2 .BBasdasd {
  position: absolute;
  bottom: 29px;
  right: 60px;
  width: 95%;
  background: #fff;
  z-index: 998;
}
.BaseTable_I .Table_left {
    float: left!important;
    width: 18.2%!important;
    height: auto;
    min-height: 655px!important;
    background: #FFFFFF;
    border-radius: 5px;
    margin: 10px 0px 0 24px!important; 
}
.BaseTable_I .Tabless .dsds .el-input, .Tables2 .dsds .el-input {
  float: right;
  width: 70%;
}
.BaseTable_I .Tabless .el_seso, .Tables2 .el_seso, .hhhh .el_seso {
  float: right;
  text-align: center;
  width: 32px;
  border: 1px solid #d9d9d9;
  border-radius: 0 4px 4px 0;
  border-left: 0;
  cursor: pointer;
}
.BaseTable_I .Tabless .el_seso i, .Tables2 .el_seso i {
  color: #8c8c8c;
}

.BaseTable_I .Rig_1 .Rigjh {
  display: inline-block;
  font-size: 14px;
  font-family: PingFang SC;
  font-weight: 500;
  color: #FFFFFF;
  width: 76px;
  height: 32px;
  text-align: center;
  line-height: 32px;
  background: #1890FF;
  border-radius: 4px;
  margin-top: 0px;
  margin-left: 60px;
  cursor: pointer;
}
.BaseTable_I .Rund {
  cursor: pointer;
  transform: rotate(90deg);
  z-index: 999;   
} 
.BaseTable_I .Peop {
  font-size: 14px;
  font-family: PingFang SC;
  font-weight: 500;
  color: #1890FF!important;
  float: right;
  margin-right: 30px;
  cursor: pointer;
}
.BaseTable_I .El_pop .el-input__inner {
  width: 230px;
  height: 32px; 
  border: 1px solid #D9D9D9;
  border-radius: 3px;
}   
.BaseTable_I .isdd {
  margin-top: 24px;
}
.BaseTable_I .Xians {
  margin-bottom: 24px;
  overflow: hidden;
}
.BaseTable_I .isddss {
  font-size: 14px;
  font-family: PingFang SC;
  font-weight: 500;
  color: #262626;
  margin-right: 14px;
  float: left;
  margin-top: 7px;
  margin-left: 38px;
}  
.BaseTable_I .Icci {
  font-size: 14px;
  font-family: PingFang SC;
  font-weight: 500;
  color: #1890FF;
  margin-left: 120px;
  cursor: pointer;
} 
.BaseTable_I .dwwwasd {
  font-size: 14px; 
  font-weight: 400;
  color: #262626;
  float: left;
  margin-right: 10px;
}
.BaseTable_I .Trtfd { 
  border: 1px solid #E8E9EC;
  border-left: 0;
  display: flex;
}
.BaseTable_I .Trtfd .Tytt {
  flex: 0 0 50%;
  border-left: 1px solid #E8E9EC;
  padding-bottom: 20px;
}
.BaseTable_I .hhhh {
  overflow: hidden;
  margin: 20px 0 0 20px;
}
.BaseTable_I .hhhh .el-input {
  width: 370px;
  float: left;
}
.BaseTable_I .hhhh .el-input__inner, .el-textarea__inner {
  width: 370px;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.BaseTable_I .hhhh .el_seso {
  float: left;
} 
.BaseTable_I .Tables2 .Tab_tt {
  width: 88px;
  height: 32px;
  background: #FFFFFF;
  border: 1px solid #D9D9D9;
  border-radius: 3px;
  color: #595959;
} 
.BaseTable_I .el-dropdown {
  position: absolute;
  right: 0;
  margin-top: -6px;
} 
.BaseTable_I .tyhh {
  float: left;
  width: 100%;
  margin-top: 15px;
}
.BaseTable_I .tyhh img {
  float: left;
  width: 24px;
  height: 24px;
  background: #CCCCCC;
  border-radius: 50%;
}
.BaseTable_I .tyhh span {
  float: left;
  margin: 2px 0 0 10px;
}
.BaseTable_I .tyhh i {
  padding-top: 8px!important;
} 
.BaseTable_I .TY_iq {
  overflow: hidden;
  margin-bottom: 30px;
} 
.BaseTable_I .TY_iq img{  
  width: 46px; 
  height: 46px;
  background: #FFFFFF;
  border-radius: 3px;
  float: left;
}
.BaseTable_I .TY_iq div {
  float: left;
  margin: 12px 0 0 12px;
}
.BaseTable_I .Blo_a {
  display: flex;
  justify-content: space-between;
  padding-right: 30px;
  padding-left: 10px;
  margin-bottom: 15px;
}
.BaseTable_I .Blo_a .Blo_a1 {
  width: 28px;
  height: 28px;
  border-radius: 100px;
  float: left;
}
.BaseTable_I .Blo_a .Blo_a2 {
  float: left;
  margin: 5px 0 0 11px;
}
.BaseTable_I .Blo_a .Blo_a3 {
  width: 16px;
  height: 16px;
  float: right;
  margin: 7px 0 0 10px;
  background: #0081FF;
}
.BaseTable_I .Blo_a .Blo_a4 {
  float: right;
  margin: 5px 0 0 0px;
}
.BaseTable_I .Blo_b {
  margin-left: 12px;
  margin-top: 15px;
}
.BaseTable_I .Blo_b img {
  float: left;
    width: 28px;
    height: 28px;
    border-radius: 50%;
    margin-right: 8px;
}
.BaseTable_I .Blo_b .el-checkbox__input {
  float: left;
  margin-top: 9px;
}
.BaseTable_I .Blo_b span {
  float: left;
  margin-top: 5px;
}
.BaseTable_I .Blacks {
  display: flex;
  margin: 10px 0 0 22px;
} 
.BaseTable_I /deep/ .kkkkkk .el-tree-node__content{
  /* justify-content: space-between; */
  position: relative;
  
}
</style>
